import { Fragment } from 'react';

import { AccountMenu, SignInButton } from '@/components/atomic/atoms';
import { useUIContext } from '@/components/context/uiContext/ManagedUiContext';

const LoginControls = ({
  isMobile,
  onHomeScreenBanner,
  onScrollNotInHomeScreen,
  pathName,
  ...rest
}) => {
  const { authUser } = useUIContext();
  const isAuthUser = Boolean(authUser?.id);
  return (
    <Fragment>
      {!isAuthUser && (
        <SignInButton
          {...{
            onHomeScreenBanner,
            onScrollNotInHomeScreen,
            pathName
          }}
        />
      )}
      {!(!isAuthUser || isMobile) && (
        <AccountMenu
          {...{
            onHomeScreenBanner,
            ...rest
          }}
        />
      )}
    </Fragment>
  );
};

export default LoginControls;
