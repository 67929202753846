import { useRouter } from 'next/router';

import { Icon } from '@/components/atomic/atoms';
import { WhyChooseHeader } from '@/components/atomic/molecules';
import { staticMediaStoreBaseURL } from '@/config/common';
import { whyChooseHaflaServices } from '@/services/homePage.service';
import { getPlanEventWithHebaURL } from '@/services/planEventWithHeba.service';

const WhyChooseHaflaOrganism = () => {
  const router = useRouter();
  const planEventWithHebaURL = getPlanEventWithHebaURL({ router });

  return (
    <div className='pt-10'>
      <WhyChooseHeader
        {...{
          buttonText: 'Plan your event',
          buttonUrl: planEventWithHebaURL,
          isOpenInSameTab: true,
          title: 'WHY CHOOSE HAFLA'
        }}
      />
      <div className='flex flex-nowrap items-start w-11/12 mx-auto justify-evenly mt-10 mb-20'>
        {whyChooseHaflaServices.map(({ id, name, image }) => (
          <Icon
            key={id}
            {...{
              alt: name,
              className: 'img-center h-30 w-30',
              containerClass: 'shadow-none',
              title: name,
              url: `${staticMediaStoreBaseURL}${image}`
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default WhyChooseHaflaOrganism;
