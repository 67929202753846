import Image from 'next/image';
import { useRouter } from 'next/router';

import { Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import { getPlanEventWithHebaURL } from '@/services/planEventWithHeba.service';

// TODO: FV sort keys
const AboutHaflaFooterContent = () => {
  const router = useRouter();
  const planEventWithHebaURL = getPlanEventWithHebaURL({ router });

  return (
    <div
      className='rounded-full text-center text-black
flex bg-white font-Montserrat font-black w-40 p-2 justify-center m-auto'
    >
      <a
        className='flex text-xs font-normal font-medium w-28'
        onClick={() => window.open(planEventWithHebaURL, '_self')}
      >
        <div className='flex flex-row w-full justify-evenly'>
          <Text
            {...{
              className: 'uppercase',
              content: 'Get Started'
            }}
          />
          <Image
            className='relative'
            alt='arrow right icon'
            height={8}
            src={`${staticMediaStoreBaseURL}/icons/arrowright.svg`}
            width={8}
          />
        </div>
      </a>
    </div>
  );
};

const AboutSectionMobile = () => (
  <div className='bg-white'>
    <div className='about-hafla-footer bg-no-repeat bg-cover'>
      <div
        className='opacity-100 h-full w-full
margin-auto text-center py-5'
      >
        <Text
          {...{
            className:
              'margin-auto text-white font-Montserrat text-lg font-black mb-2',
            content: 'Your event is just a click away!'
          }}
        />
        <AboutHaflaFooterContent />
      </div>
    </div>
  </div>
);

export default AboutSectionMobile;
