import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import {
  LogoutConfirmationModal,
  UserProfileTabItem
} from '@/components/atomic/atoms';
import { Text } from '@/components/atomic/nuclei';
import { useUIContext } from '@/components/context/uiContext/ManagedUiContext';
import { staticMediaStoreBaseURL } from '@/config/common';
import userProfileTabList from '@/helpers/userProfileTabList';

const UserProfileLayout = ({
  children,
  isLoading,
  myProfile,
  name,
  showSideNav
}) => {
  const Router = useRouter();
  const [activeSelected, setActiveSelected] = useState('');
  const { logOut } = useUIContext();
  const [showLogoutConfirmationModal, setShowLogoutConfirmationModal] =
    useState(false);

  useEffect(() => {
    if (Router.asPath) {
      const selected = Router.asPath.split('/');
      setActiveSelected(selected[2] ?? userProfileTabList[0].routePath);
    }
  }, [Router.asPath]);

  const LogoutButton = () => (
    <div
      className='md:hidden w-full flex justify-center text-dim-gray'
      onClick={() => setShowLogoutConfirmationModal(true)}
    >
      <Image
        alt='profile'
        height={15}
        objectFit='initial'
        src={`${staticMediaStoreBaseURL}/icons/logout-icon.svg`}
        width={15}
      />
      <Text
        {...{
          className: 'ms-1',
          content: 'Logout',
          HtmlTag: 'span'
        }}
      />
    </div>
  );

  return (
    <div className='md:w-5/6 xl:w-5/6 2xl:w-9/12 mx-auto'>
      {showLogoutConfirmationModal && (
        <LogoutConfirmationModal
          logOut={logOut}
          setShowLogoutConfirmationModal={setShowLogoutConfirmationModal}
        />
      )}
      <Text
        {...{
          className: 'hidden md:block font-medium text-xl py-8',
          content: 'Profile',
          HtmlTag: 'header'
        }}
      />
      <div className='md:border border-platinum rounded-2.5'>
        <div
          className={`w-full bg-ghost-white h-21.5 p-7 block ${
            showSideNav ? '' : 'hidden md:flex'
          }`}
        >
          {!isLoading && (
            <div className='text-nero font-bold text-sm md:text-2xl justify-start'>
              <Text
                {...{
                  content: `Hi, ${name || 'there!'}`,
                  HtmlTag: 'span'
                }}
              />
            </div>
          )}
        </div>
        <div className='w-full flex'>
          <div
            className={`md:max-w-xs xl:max-w-94 flex-shrink-0 w-full md:border-r ${
              showSideNav ? 'flex' : 'hidden md:flex'
            } flex-col p-5 md:p-7 pb-13`}
          >
            {userProfileTabList.map(
              (
                { icon, iconBackground, routePath, tabName, toNavigate },
                index
              ) => (
                <UserProfileTabItem
                  key={index}
                  {...{
                    activeSelected,
                    icon,
                    iconBackground,
                    routePath,
                    tabName,
                    toNavigate
                  }}
                />
              )
            )}
            <LogoutButton />
          </div>
          <div className='w-full'>{children}</div>
        </div>
      </div>
      {myProfile && <div className='md:hidden h-14'></div>}
    </div>
  );
};

export default UserProfileLayout;
