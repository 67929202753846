import dynamic from 'next/dynamic';

import { LoginComponent } from '@/components/atomic/molecules';

const DynamicComponentWithNoSSR = dynamic(
  () => import('@/components/atomic/atoms/login/AuthenticateLogin'),
  { ssr: false }
);

const LoginWithOTPOrganism = ({
  otpSent,
  setOtpSent,
  OTP,
  setOTP,
  countryCode,
  setCountryCode,
  mobile,
  setMobile,
  setIsLoggedIn
}) =>
  !otpSent ? (
    <LoginComponent
      {...{
        otpSent,
        setOtpSent,
        OTP,
        setOTP,
        countryCode,
        setCountryCode,
        mobile,
        setMobile
      }}
    />
  ) : (
    <DynamicComponentWithNoSSR
      {...{
        setOtpSent,
        OTP,
        setOTP,
        countryCode,
        mobile,
        setIsLoggedIn
      }}
    />
  );

export default LoginWithOTPOrganism;
