import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';
import { useRef, useState } from 'react';

import { Heading, ImageCarouselModal } from '@/components/atomic/atoms';
import { Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import { transformImagesForGridAndCarousel } from '@/helpers/carousel';
import { useIsMobile } from '@/helpers/screenResolutionHelper';
import {
  GTM_EVENTS_CTA_NAME,
  PAGE_NAME,
  captureGTMEventCTAClick
} from '@/lib/gtm';
import { getEventGalleryRouteLabel } from '@/services/homePage.service';
import { getPageURL } from '@/services/hostPortal.service';

const handleNav = (direction, navRef) => {
  const navRefControl = { ...navRef };
  if (navRefControl) {
    if (direction === 'left') {
      navRefControl.current.scrollLeft -= 300;
    } else {
      navRefControl.current.scrollLeft += 300;
    }
  }
};

const Arrow = ({ direction, iconPath, navRef, style }) => (
  <div
    className={`border-gray border w-full max-w-7 h-7 rounded-full p-1.5 ${style} hidden md:flex justify-center cursor-pointer hover:bg-gray`}
    onClick={() => handleNav(direction, navRef)}
  >
    <Image
      alt='arrow'
      height={12}
      objectFit='contain'
      src={`${staticMediaStoreBaseURL}${iconPath}`}
      width={10}
    />
  </div>
);

const EventGalleryWeb = ({
  eventGallery,
  marginTop,
  eventCategory,
  title,
  b2bPage
}) => {
  const [isMobile] = useIsMobile();
  const navRef = useRef(null);
  const [showImageModal, setShowImageModal] = useState(false);
  const eventGalleryTranslation = useTranslation('eventGallery');
  const eventTitle = eventGalleryTranslation.t(title);
  const openImageModal = (image) => {
    setShowImageModal(image);
  };

  const eventGalleryImagesTransformed = transformImagesForGridAndCarousel(
    eventGallery?.eventCategory.images || []
  );

  const galleryPageURL = getPageURL({
    pageName: getEventGalleryRouteLabel({ eventCategory })
  });

  return (
    <div className='bg-white py-10 md:py-30'>
      <div className={`overflow-hidden ${marginTop || ''} md:h-full`}>
        <div className='relative'>
          {!b2bPage ? (
            <div className='px-5 md:px-20 md:flex justify-between items-end w-full'>
              <div className='md:w-108 w-68 ml-0'>
                <Heading
                  level={3}
                  className='text-2xl lg:text-45px text-nero leading-8 lg:leading-12.5 uppercase'
                >
                  {eventTitle}
                </Heading>
              </div>
              <Link href={galleryPageURL}>
                <div
                  className={`absolute md:relative right-5 top-10 text-xs md:text-sm font-Montserrat font-medium text-dim-gray hover:text-nero h-auto self-end md:pb-13.5 me-2 cursor-pointer`}
                >
                  <Text
                    {...{
                      className: 'me-1.5 md:me-2',
                      content: 'View all',
                      HtmlTag: 'a'
                    }}
                  />
                  <Image
                    alt='right-angle'
                    className='self-end'
                    height={isMobile ? 10 : 12}
                    objectFit='contain'
                    src={`${staticMediaStoreBaseURL}/icons/right-angle.svg`}
                    width={isMobile ? 8 : 10}
                  />
                </div>
              </Link>
            </div>
          ) : (
            <div className='w-full mx-auto px-5 md:px-20 flex flex-row items-end'>
              <div className='w-full md:w-11/12'>
                <div className='md:max-w-md mx-auto relative text-center md:text-right'>
                  <Heading
                    level={3}
                    className='text-2xl lg:text-45px text-center text-nero leading-8 lg:leading-12.5 uppercase'
                  >
                    {eventTitle}
                  </Heading>
                </div>
              </div>
              <Link href={galleryPageURL}>
                <div className='w-1/12 text-center hidden md:block'>
                  <a
                    onClick={() =>
                      captureGTMEventCTAClick({
                        ctaName: GTM_EVENTS_CTA_NAME.GALLERY_VIEW_ALL,
                        pageName: PAGE_NAME.HOME_PAGE.label
                      })
                    }
                  >
                    View all
                  </a>
                  <Image
                    alt='right-angle'
                    className='self-end'
                    height={isMobile ? 10 : 12}
                    objectFit='contain'
                    src={`${staticMediaStoreBaseURL}/icons/right-angle.svg`}
                    width={isMobile ? 8 : 10}
                  />
                </div>
              </Link>
            </div>
          )}
          <div className='flex justify-center items-center px-5 md:px-15'>
            <Arrow
              alt='Left arrow'
              direction={'left'}
              iconPath={'/icons/left.svg'}
              navRef={navRef}
              style='md:pl-1'
            />
            <div
              className='relative no-scrollbar flex overflow-y-auto mt-6 md:mt-10 mx-4'
              ref={navRef}
            >
              {eventGalleryImagesTransformed.map(
                (image, index) =>
                  index < 7 && (
                    <div
                      key={image.id}
                      onClick={
                        index < 6 ? () => openImageModal(image) : () => ''
                      }
                      className={
                        'relative cursor-pointer rounded-lg overflow-hidden flex-shrink-0 me-3 w-56 h-43 md:h-71.5 md:w-95 bg-cover'
                      }
                      style={{
                        backgroundImage: `url('${staticMediaStoreBaseURL}${image.imageUrl}')`
                      }}
                    >
                      {
                        <div
                          className={`${
                            index < 6
                              ? 'hover:bg-black hover:opacity-70 opacity-0'
                              : 'bg-black opacity-70'
                          } p-10 rounded-lg w-full h-57 md:h-87.5 xl:h-100 flex justify-center`}
                        >
                          <div className='absolute top-33.5 left-5.5 z-20 text-white transition duration-500 ease-in-out'>
                            <p className='text-sm md:text-lg md:font-semibold font-semibold leading-4 max-w-74'>
                              {index < 6 ? (
                                image.eventName
                              ) : (
                                <Link href={galleryPageURL}>
                                  <a>view more</a>
                                </Link>
                              )}
                            </p>
                          </div>
                        </div>
                      }
                    </div>
                  )
              )}
            </div>
            <Arrow
              alt='Right arrow'
              direction={'right'}
              iconPath={'/icons/right-angle.svg'}
              navRef={navRef}
              style='md:pr-1'
            />
          </div>
        </div>
        {showImageModal && (
          <ImageCarouselModal
            {...{
              modalImage: showImageModal,
              openImageModal,
              images: eventGalleryImagesTransformed
            }}
          />
        )}
      </div>
    </div>
  );
};

export default EventGalleryWeb;
