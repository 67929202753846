import dynamic from 'next/dynamic';

import { CollectionHeader } from '@/components/atomic/molecules';
import { eventGlimpsesList } from '@/services/homePage.service';

const VideoCard = dynamic(
  () => import('@/components/atomic/atoms/homePage/VideoCard'),
  {
    ssr: false
  }
);

const EventsGlimpsesWebOrganism = () => (
  <section className='py-7 md:py-10 px-5 md:px-20 md:mb-5 mx-10'>
    <CollectionHeader
      {...{
        title: 'A Glimpse into our Events',
        subTitle: 'A tour of events we have executed.',
        isOpenInSameTab: true
      }}
    />
    <div className='grid grid-cols-3 gap-4 mt-10 justify-items-center'>
      {eventGlimpsesList.map(({ id, url }) => (
        <VideoCard
          key={id}
          {...{ url }}
        />
      ))}
    </div>
  </section>
);

export default EventsGlimpsesWebOrganism;
