import { CartCardBody, CartCardHeader } from '@/components/atomic/atoms';

const CartCard = ({
  cartName,
  cartNumber,
  cartOrder,
  createdAt,
  opsUser,
  status
} = {}) => (
  <div
    className={`py-5 my-5 md:border text-sm md:text-base border-platinum rounded-lg px-4 md:px-6 bg-lightgray font-medium hover:border-cinnabar shadow-event cursor-pointer`}
  >
    <CartCardHeader
      {...{
        cartName,
        cartNumber,
        status
      }}
    />
    <CartCardBody
      {...{
        cartCreatedBy: opsUser?.name || 'NA',
        createdAt,
        orderNumber: cartOrder?.order.orderNumber,
        status
      }}
    />
  </div>
);

export default CartCard;
