import Image from 'next/image';
import { Fragment } from 'react';

import { Text } from '@/components/atomic/nuclei';
import { mediaStoreBaseURL, staticMediaStoreBaseURL } from '@/config/common';
import { formatDateToHaflaStandardFormat, formatTimeToAmPm } from '@/lib/time';

const SideBarHead = ({ displayUserEventProfile, isMobile, userEvent }) => (
  <div className='flex flex-row md:flex-col gap-4'>
    <div className={`${isMobile ? 'w-20' : 'w-40'} rounded-lg`}>
      <Image
        alt='user event icon'
        className='rounded-lg'
        height={isMobile ? '80' : '160'}
        id='user-event-icon'
        layout='fixed'
        priority='true'
        src={`${mediaStoreBaseURL}/${userEvent.userEventDetails[0].checkoutEvent.iconUrl}`}
        width={isMobile ? '80' : '160'}
      />
    </div>
    <div className='flex flex-col'>
      <Text
        className='text-nero text-xl md:text-3xl font-semibold'
        content={userEvent.userEventDetails[0].checkoutEvent?.event.name}
      />
      <div className='flex justify-start mb-2 mt-1'>
        <div
          className='flex gap-4 md:gap-2'
          onClick={displayUserEventProfile}
        >
          <Image
            alt='view icon'
            height={15}
            src={`${staticMediaStoreBaseURL}/icons/view.svg`}
            width={15}
          />
          <Text
            className='text-brand font-normal cursor-pointer self-start'
            content='view'
          />
        </div>
      </div>
    </div>
  </div>
);

const SideBarBody = ({ isMobile, userEvent }) => {
  const { userEventDetails, zendeskTicketId } = userEvent;
  const [{ eventContactName, eventDate, eventStartTime, eventAddress }] =
    userEventDetails;
  const iconHeightAndWidth = isMobile ? '24' : '28';
  return (
    <Fragment>
      <div className='flex justify-start gap-4'>
        <div className='flex gap-4 self-center'>
          <Image
            alt='zendesk icon'
            height={iconHeightAndWidth}
            src={`${staticMediaStoreBaseURL}/icons/zendesk-icon.svg`}
            width={iconHeightAndWidth}
          />
          <Text
            className={`text-sm md:text-base font-medium cursor-pointer self-center ${
              zendeskTicketId ? '' : 'text-error-base'
            }`}
            content={zendeskTicketId || 'N/A'}
          />
        </div>
      </div>
      <div className='flex justify-start gap-4 my-2'>
        <div className='flex gap-4'>
          <div className='flex self-center'>
            <Image
              alt='user icon'
              height={iconHeightAndWidth}
              layout='fixed'
              src={`${staticMediaStoreBaseURL}/icons/user-red.svg`}
              width={iconHeightAndWidth}
            />
          </div>
          <Text
            className='font-medium self-center'
            content={eventContactName}
          />
        </div>
      </div>
      <div className='flex justify-start gap-4 my-2'>
        <div className='flex gap-4 self-center'>
          <Image
            alt='event date'
            height={iconHeightAndWidth}
            src={`${staticMediaStoreBaseURL}/icons/calender-red.svg`}
            width={iconHeightAndWidth}
          />
          <Text
            className='text-sm md:text-base self-center font-medium'
            content={formatDateToHaflaStandardFormat(eventDate)}
          />
        </div>
      </div>
      <div className='flex justify-start gap-4 my-2'>
        <div className='flex gap-4'>
          <div className='w-6 self-center flex'>
            <Image
              alt='clock icon'
              height={iconHeightAndWidth}
              layout='fixed'
              src={`${staticMediaStoreBaseURL}/icons/clock-icon.svg`}
              width={iconHeightAndWidth}
            />
          </div>
          <Text
            className='text-sm md:text-base self-center flex-1 font-medium'
            content={formatTimeToAmPm(eventStartTime)}
          />
        </div>
      </div>
      <div className='flex justify-start gap-4'>
        <div className='flex gap-4'>
          <div className='w-6'>
            <Image
              alt='location icon'
              height={iconHeightAndWidth}
              layout='fixed'
              src={`${staticMediaStoreBaseURL}/icons/location-icon.svg`}
              width={iconHeightAndWidth}
            />
          </div>
          <Text
            className='text-sm md:text-base flex-1 font-medium align-center'
            content={eventAddress?.formattedAddress}
          />
        </div>
      </div>
    </Fragment>
  );
};

const UserEventCartDetailsSideBarOrganism = ({
  displayUserEventProfile,
  isMobile,
  userEvent
}) => (
  <div className='md:w-108 bg-white px-6 py-4 md:pl-24 md:pr-10 md:pt-5 flex flex-col gap-3'>
    <SideBarHead
      {...{
        displayUserEventProfile,
        isMobile,
        userEvent
      }}
    />
    <SideBarBody
      {...{
        isMobile,
        userEvent
      }}
    />
  </div>
);

export default UserEventCartDetailsSideBarOrganism;
