import { Fragment, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useForm } from 'react-hook-form';

import {
  EventDateRow,
  EventTypeRow,
  FieldsComponent,
  FormattedAddress,
  FormInputView,
  TRNNumberAndBudgetRow
} from '@/components/atomic/atoms';
import {
  EmailFestivalRow,
  EventCartDetailHeader
} from '@/components/atomic/molecules';
import { userAddressObject } from '@/helpers/eventDetails';
import { getCheckoutEventsAPI } from '@/services/hostCart.service';

const UserEventProfileCustomerOrganism = ({
  authUser,
  setShowUserEventProfile,
  userEvent
}) => {
  const {
    userEventDetails: [userEventDetails]
  } = userEvent;
  const {
    clearErrors,
    control,
    formState: { errors },
    getValues,
    register,
    setValue
  } = useForm({
    defaultValues: {
      eventContactName: authUser.name || '',
      eventContactEmail: authUser.email || '',
      ...(userEventDetails || {}),
      opportunityValue: userEventDetails?.opportunityValue || '',
      budget: userEventDetails?.budget || ''
    }
  });

  const [eventFormDetails, setEventFormDetails] = useState({
    checkoutEvents: [],
    cityList: [],
    opsUsersList: [],
    selectedEventDate: '',
    selectedEventStartTime: userEventDetails?.eventStartTime?.slice(0, 5) || '',
    showAddAddress: false,
    showAddressError: false,
    toastMessage: '',
    userAddress: userEventDetails?.eventAddress || userAddressObject,
    userEventId: userEvent?.id,
    zendeskTicketId: ''
  });

  const fetchEventTypes = async () => {
    const { status, entity } = await getCheckoutEventsAPI();
    if (status && entity) {
      const event = entity.map((events) => ({
        id: events.id,
        name: events.event.name
      }));
      setEventFormDetails(() => {
        eventFormDetails.checkoutEvents = event;
        return { ...eventFormDetails };
      });
    }
  };

  useEffect(() => {
    fetchEventTypes();
    if (userEventDetails?.eventDate) {
      setEventFormDetails(() => {
        eventFormDetails.selectedEventDate = new Date(
          userEventDetails?.eventDate || ''
        );
        return { ...eventFormDetails };
      });
    }
  }, []);

  const onExitClick = () => {
    setShowUserEventProfile(false);
  };

  const fieldsWithProps = [
    {
      ComponentName: FormInputView,
      props: { label: 'Contact Name', dbKeyName: 'eventContactName' }
    },
    { ComponentName: EmailFestivalRow, props: { onlyEmailField: true } },
    {
      ComponentName: EventTypeRow,
      props: {
        eventTypeOptions: eventFormDetails.checkoutEvents,
        placeholderLabel: 'Event Type'
      }
    },
    {
      ComponentName: EventDateRow,
      props: {
        selectedEventDate: eventFormDetails.selectedEventDate,
        selectedEventStartTime: eventFormDetails.selectedEventStartTime
      }
    },
    {
      ComponentName: FormattedAddress,
      props: { userAddress: eventFormDetails.userAddress, disabled: true }
    },
    { ComponentName: TRNNumberAndBudgetRow }
  ];

  return (
    <Fragment>
      <EventCartDetailHeader
        {...{
          backward: {
            onClick: () => onExitClick()
          },
          forward: {
            show: false
          },
          heading: 'View Event',
          type: 'viewEventDetail'
        }}
      />
      <div className='w-full mt-4 md:mt-0'>
        <div className='w-full shadow-profile md:shadow-delivery rounded-2.5'>
          <div className='p-6 md:p-7'>
            <FieldsComponent
              {...{
                clearErrors,
                control,
                disabled: true,
                errors,
                fieldsWithProps,
                getValues,
                register,
                setValue
              }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UserEventProfileCustomerOrganism;
