import Image from 'next/image';

import { Heading, Paragraph } from '@/components/atomic/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';

const PlanDescription = ({
  eventDescription,
  eventName,
  iconUrl,
  index,
  isLastItem,
  usageExample
}) => (
  <div
    className={`md:flex md:justify-between md:p-10 ${
      index !== 0 ? 'md:border-t border-dashed border-gray mt-10 md:mt-0' : ''
    } ${isLastItem ? 'last:pb-0' : ''}`}
  >
    <div
      className={`w-full md:w-1/5 max-w-15 mx-auto md:max-w-full md:mx-none ${
        index % 2 === 0 ? 'order-1 md:order-2' : ''
      }`}
    >
      <Image
        alt='events'
        height={96}
        layout='fixed'
        src={`${staticMediaStoreBaseURL}${iconUrl}`}
        width={97}
      />
    </div>

    <div className='w-full md:w-4/5 text-center md:text-left md:max-w-116.5 block md:hidden'>
      <Heading
        className='my-4 capitalize text-lg md:text-28px font-normal font-Montserrat text-black leading-5 md:leading-8 mb-3'
        level={3}
      >
        {eventName}
      </Heading>
      <div className='border-solid border-b-2 border-nero w-5 md:w-15 mb-3 md:mb-7 mx-auto'></div>
    </div>

    <div
      className={`w-full md:w-4/5 text-center md:text-left md:max-w-116.5 ${
        index % 2 === 0 ? 'order-2 md:order-1' : ''
      }`}
    >
      <div className='hidden md:block'>
        <Heading
          level={3}
          className='capitalize text-lg md:text-28px font-normal font-Montserrat text-black leading-5 md:leading-8 mb-2'
        >
          {eventName}
        </Heading>
        <div className='border-solid border-b-2 border-nero w-5 md:w-15 mb-3 md:mb-7'></div>
      </div>

      <Paragraph
        {...{
          className: 'text-sm md:text-base font-medium text-dim-gray',
          content: eventDescription
        }}
      />
      <Paragraph
        {...{
          className:
            'italic text-sm md:text-base font-medium text-dim-gray mt-4',
          content: usageExample
        }}
      />
    </div>
  </div>
);

const EventPlanning = ({ eventPlanInfo, eventPlanHeading }) => (
  <div className='container mx-auto'>
    <div className='max-w-40 md:max-w-75 mx-auto block mb-7 md:mb-10'>
      <Heading
        level={3}
        className='text-2xl md:text-45px text-nero text-center leading-5 md:leading-10 mb-2'
      >
        {' '}
        {eventPlanHeading}{' '}
      </Heading>
      <Paragraph
        {...{
          className: 'text-center text-sm md:text-base font-medium',
          content: `Let’s get planning!`
        }}
      />
    </div>

    <div className='max-w-68 mx-auto md:max-w-3xl block'>
      {eventPlanInfo && eventPlanInfo.length
        ? eventPlanInfo.map((eventPlan, index) => {
          const { eventDescription, eventName, iconUrl, usageExample } =
              eventPlan;
          return (
            <PlanDescription
              eventDescription={eventDescription}
              eventName={eventName}
              iconUrl={iconUrl}
              index={index}
              isLastItem={index === eventPlanInfo.length - 1}
              key={index}
              usageExample={usageExample}
            />
          );
        })
        : ''}
    </div>
  </div>
);

export default EventPlanning;
