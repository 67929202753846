import { Button, Text, TextKeyValuePair } from '@/components/atomic/nuclei';

const GenericKeyValueBox = ({ boxTitle, buttonProps, keyValuePairList }) => (
  <div className='flex flex-col gap-2 md:gap-4 self-baseline border border-platinum bg-lightgray p-4 md:p-6 w-full rounded-lg'>
    <Text
      {...{
        className: 'font-medium text-base',
        content: boxTitle
      }}
    />
    <div className='flex flex-col gap-2 md:gap-4 justify-between text-sm font-light md:font-normal text-nero'>
      {keyValuePairList.map(({ label, value }, index) => (
        <TextKeyValuePair
          key={index}
          {...{
            className: 'justify-between w-full',
            label,
            labelClass: 'text-sm font-light md:font-normal',
            spaceTop: '',
            value,
            valueClassName: 'text-sm font-light md:font-normal align-right'
          }}
        />
      ))}
    </div>
    {buttonProps && <Button {...buttonProps} />}
  </div>
);

export default GenericKeyValueBox;
