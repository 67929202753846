import Image from 'next/image';
import { useRouter } from 'next/router';

import { Text, TextKeyValuePair } from '@/components/atomic/nuclei';
import {
  homePageBannerDescriptionIconValuePair,
  staticMediaStoreBaseURL
} from '@/config/common';
import { getPlanEventWithHebaURL } from '@/services/planEventWithHeba.service';

const PlanWithHaflaCTA = () => {
  const router = useRouter();
  const planEventWithHebaURL = getPlanEventWithHebaURL({ router });

  return (
    <div
      className={`flex flex-row cursor-pointer z-10 rounded-full h-9 w-52 z-10 bg-white items-center place-content-center plan-with-ai-style self-center my-4 justify-between`}
    >
      <Text
        {...{
          className:
            'uppercase font-medium text-sm leading-6 plan-with-ai-text pl-7 tracking-widest',
          content: 'plan with hafla ai',
          onClick: () => {
            window.open(planEventWithHebaURL, '_self');
          }
        }}
      />
      <div className='flex items-center plan-with-ai-arrow-style rounded-full w-7 h-7 text-center place-content-center mr-2'>
        <Image
          className='self-center transform rotate-180'
          alt='plan with hafla ai icon'
          height={7}
          layout='fixed'
          src={`${staticMediaStoreBaseURL}/icons/back.svg`}
          width={11.2}
        />
      </div>
    </div>
  );
};

const MobileHomeBanner = () => (
  <div className='flex flex-col h-154 bg-brand-gradient'>
    <div className='flex justify-end self-end hero-background-mobile'>
      <Image
        alt='Hafla mobile banner'
        className='home-page-banner-image'
        height={328}
        objectFit='cover'
        layout='fixed'
        src={`${staticMediaStoreBaseURL}/home-page-banner/home-page-banner.png`}
        width={390}
      />
    </div>
    <div className='bg-hero-banner-bottom-background bg-no-repeat bg-cover relative bottom-16 content-center'>
      <div className='flex flex-1 flex-col gap-1 pl-3 pt-18 pb-4'>
        <div className='pl-3'>
          <TextKeyValuePair
            {...{
              className: 'flex-col gap-half',
              label: 'Experience AI Powered',
              labelClass: 'text-28px text-white font-medium hero-heading z-10',
              spaceTop: '',
              value: 'Event Planning',
              valueClassName:
                'text-28px text-white font-medium hero-heading z-10'
            }}
          />
          <TextKeyValuePair
            {...{
              className: 'flex-col gap-half pt-2 font-medium w-full',
              label: 'By Expert Planners, with Professional Partners, ',
              labelClass: 'text-white text-xs z-10',
              spaceTop: '',
              value: 'for Everything for Entire Event',
              valueClassName: 'text-white text-xs z-10'
            }}
          />
        </div>
        <div className='flex justify-between pt-5 pb-2 pl-3'>
          <div className='flex flex-col gap-1.5'>
            {homePageBannerDescriptionIconValuePair.map(({ icon, title }) => (
              <div
                key={title}
                className='flex gap-2 items-center'
              >
                <Image
                  width={20}
                  height={20}
                  layout='fixed'
                  src={`${staticMediaStoreBaseURL}/icons/${icon}`}
                  alt='ideas icon'
                />
                <Text
                  {...{
                    className: 'text-white text-xs font-medium',
                    content: title
                  }}
                />
              </div>
            ))}
          </div>
        </div>
        <PlanWithHaflaCTA />
      </div>
    </div>
  </div>
);

export default MobileHomeBanner;
