export const userAddressObject = {
  formattedAddress: '',
  houseNumber: '',
  buildingName: '',
  landmark: '',
  latitude: 0,
  longitude: 0,
  locationSelected: false,
  cityId: ''
};

export default userAddressObject; // TODO: Remove after add another export function or var.
