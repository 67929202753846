import Image from 'next/image';
import { useState } from 'react';

import { CartCard } from '@/components/atomic/molecules';
import { Text } from '@/components/atomic/nuclei';
import { CART_STATUS, staticMediaStoreBaseURL } from '@/config/common';
import { getPageURL, ROUTE_CONFIG } from '@/services/hostPortal.service';

const onCartCardClick = ({ cartOrder, id, router, status }) => {
  if (status === CART_STATUS.ORDER) {
    const orderId = cartOrder?.order?.id;
    const pathForOrderedCart = getPageURL({
      pageName: ROUTE_CONFIG.ORDER_DETAILS.label,
      pathParams: {
        orderId
      }
    });
    return router.push(pathForOrderedCart);
  }
  const cartPath = getPageURL({
    pageName: ROUTE_CONFIG.HOST_CART.label,
    pathParams: {
      cartId: id
    }
  });
  return router.push(cartPath);
};

const CartListOrganism = ({
  cartCardList,
  isMobile,
  router,
  showControlToCollapseList,
  title
}) => {
  const [showCartCardList, setShowCartCardList] = useState(true);
  return (
    <div>
      <div className='flex gap-2 mt-6'>
        <Text
          {...{
            className:
              'text-base md:text-2xl font-medium font-Montserrat flex self-center',
            content: title
          }}
        />
        {showControlToCollapseList && (
          <Image
            {...{
              alt: 'show hide details',
              className: `cursor-pointer transform ${
                showCartCardList ? 'rotate-0' : '-rotate-90'
              }`,
              height: isMobile ? 12 : 14,
              onClick: () => setShowCartCardList(!showCartCardList),
              src: `${staticMediaStoreBaseURL}/icons/down-arrow.svg`,
              width: isMobile ? 12 : 14
            }}
          />
        )}
      </div>
      <div>
        {showCartCardList &&
          cartCardList.map(
            ({
              cartName,
              cartNumber,
              cartOrder,
              createdAt,
              id,
              opsUser,
              status
            }) => (
              <div
                id='cartCard'
                key={cartNumber}
                onClick={() =>
                  onCartCardClick({
                    cartOrder,
                    id,
                    router,
                    status
                  })
                }
              >
                <CartCard
                  key={id}
                  {...{
                    cartName,
                    cartNumber,
                    cartOrder,
                    createdAt,
                    opsUser,
                    status
                  }}
                />
              </div>
            )
          )}
      </div>
    </div>
  );
};

export default CartListOrganism;
