import Image from 'next/image';
import { useState } from 'react';
import {
  CheckboxFilter,
  NumericRefinementListFilter,
  RangeFilter,
  RangeSlider,
  SearchkitProvider,
  TermQuery
} from 'searchkit';

import { Text, TextKeyValuePair } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';

const discountsList = [
  {
    from: 0,
    title: 'Below 10%',
    to: 10
  },
  {
    from: 10,
    title: '10% and Above'
  },
  {
    from: 20,
    title: '20% and Above'
  },
  {
    from: 30,
    title: '30% and Above'
  },
  {
    from: 40,
    title: '40% and Above'
  }
];

const PriceRange = ({ minValue, maxValue }) => (
  <div className='max-w-50 md:max-w-58 relative mb-7 ms-4.5'>
    <TextKeyValuePair
      {...{
        label: 'Selected price range',
        labelClass:
          'text-xs text-nero block font-normal leading-4 select-none -ml-4',
        value: `AED ${minValue} - AED ${maxValue}`,
        valueClassName:
          'text-sm text-nero block font-semibold leading-4 pb-6 select-none -ml-4'
      }}
    />
    <RangeSlider
      mod='custom-slider'
      {...{ minValue, maxValue }}
    />
  </div>
);

const Checkbox = ({ selectedItems, toggleItem }) => (
  <ul id='instantprice'>
    <li className='flex justify-start items-center pb-11px'>
      <label>
        <div className='flex items-center'>
          <input
            checked={selectedItems.length}
            className='rounded-sm box-border text-nero focus:border-nero focus:ring-nero w-4 h-4'
            name='Instant Price Products'
            onChange={toggleItem}
            type='checkbox'
          />
          <Text
            className='text-nero font-normal text-sm leading-17px capitalize ml-3'
            content='Instant Price Products Only'
          />
        </div>
      </label>
    </li>
  </ul>
);

const DiscountItem = ({ props, showDiscountFilter }) =>
  props?.items.map(({ key: discountItemKey }) => (
    <label
      className={`flex items-center mb-3 cursor-pointer ${
        !showDiscountFilter ? 'hidden' : ''
      }`}
      key={discountItemKey}
      onClick={(e) => {
        e.preventDefault();
        props.toggleItem();
        const setItem =
          props.selectedItems[0] !== discountItemKey ? discountItemKey : '';
        props.setItems(setItem);
      }}
    >
      <input
        checked={props.selectedItems[0] === discountItemKey}
        className='rounded box-border border-gray border-1 text-nero focus:border-nero focus:ring-nero w-4 h-4'
        name={discountItemKey}
        type='radio'
      />
      <Text
        className='ms-3 text-nero font-normal font-Montserrat text-sm leading-17px select-none'
        content={discountItemKey}
      />
    </label>
  ));

const CollectionsListSidebar = ({ priceRange, searchkit }) => {
  const [showDiscountFilter, setShowDiscountFilter] = useState(true);

  return (
    <div className='md:w-82 border-r border-gray me-6 md:sticky md:top-0 hidden md:block h-full'>
      <Text
        className='text-lg text-nero capitalize pb-4 block font-Montserrat font-medium'
        content='Filters'
      />
      <div className='pb-32'>
        <div
          className='border-t border-gray block border-b'
          id='price'
        >
          <SearchkitProvider searchkit={searchkit}>
            <div className='cursor-pointer py-4'>
              <Text
                className='text-base text-nero capitalize block leading-5 font-medium select-none'
                content='Price'
              />
            </div>
            <CheckboxFilter
              className='flex items-center cursor-pointerrounded-sm box-border text-nero focus:border-nero focus:ring-nero w-4 h-4'
              filter={TermQuery('isInstantPrice', true)}
              id='instantprice'
              label='instant price'
              listComponent={Checkbox}
              mod='rounded-sm box-border text-nero focus:border-nero focus:ring-nero w-4 h-4'
              showCount={false}
              title='instantprice'
            />
            {priceRange?.max_price?.value > 0 && (
              <RangeFilter
                field='price.sortPrice'
                id='sortPrice'
                max={priceRange?.max_price?.value}
                min={priceRange?.min_price?.value}
                rangeComponent={PriceRange}
                showHistogram={false}
                title={' '}
              />
            )}
            <div
              className='border-t border-gray'
              id='discount-box'
            >
              <div
                className='flex items-center justify-between cursor-pointer py-4'
                onClick={() => setShowDiscountFilter(!showDiscountFilter)}
              >
                <Text
                  className='text-lg text-nero capitalize block leading-5 font-medium select-none'
                  content='Discount'
                />
                <div className='pe-4'>
                  <Image
                    alt='dropdown-arrow'
                    height={0}
                    src={`${staticMediaStoreBaseURL}/icons/down-arrow.svg`}
                    style={{ height: 12, width: 12 }}
                    width={0}
                  />
                </div>
              </div>
              <NumericRefinementListFilter
                field='discount'
                id='discount'
                listComponent={(props) => (
                  <DiscountItem {...{ props, showDiscountFilter }} />
                )}
                mod='mr-1'
                options={discountsList}
                showCount={false}
                title=''
              />
            </div>
          </SearchkitProvider>
        </div>
      </div>
    </div>
  );
};

export default CollectionsListSidebar;
