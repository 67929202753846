import Image from 'next/image';
import { Fragment, useEffect, useState } from 'react';

import { CartIcon, SelectCartModal } from '@/components/atomic/atoms';
import { useUIContext } from '@/components/context/uiContext/ManagedUiContext';
import { CART_STATUS, staticMediaStoreBaseURL } from '@/config/common';
import { convertToDateFormat } from '@/lib/time';
import { COOKIE_MAP, getCookie } from '@/services/cookie.service';
import { getPageURL, ROUTE_CONFIG } from '@/services/hostPortal.service';
import { getUserEventDetailsById } from '@/services/myEvents.service';

const onClickDefaultCart = ({ id, Router }) => {
  const pageName = id
    ? ROUTE_CONFIG.HOST_CART.label
    : ROUTE_CONFIG.EMPTY_DEFAULT_CART.label;
  const pathParams = id
    ? {
      cartId: id
    }
    : {};

  const cartNavigateURL = getPageURL({
    pageName,
    pathParams
  });
  Router.push(cartNavigateURL);
};

const getUserEventInfoForCartSelector = (userEvent) => {
  const { userEventDetails: [userEventDetails] = [] } = userEvent || {};
  const { eventDate, eventTitle, checkoutEvent } = userEventDetails || {};
  const formattedEventDate = convertToDateFormat(eventDate, 'DD MMM');
  const userEventInformation = {
    eventDate: formattedEventDate,
    eventTitle: eventTitle || checkoutEvent?.event.name || 'Celebration'
  };
  return formattedEventDate ? userEventInformation : {};
};

const getCartListWithSelectedCartFlagged = (cartList = []) => {
  const currentSelectedCart = getCookie({ name: COOKIE_MAP.SELECTED_CART });
  return cartList.map((cart) => ({
    isSelected: cart.id === currentSelectedCart?.id,
    ...cart
  }));
};

const getCartList = (cartListRaw) => {
  const dummyDefaultCart = {
    cartName: 'Default Cart',
    cartNumber: '',
    id: '',
    isDefault: true,
    status: '-'
  };

  const cartList = getCartListWithSelectedCartFlagged(cartListRaw);

  const cartListHasDefaultCart = cartList.some(({ isDefault }) => isDefault);

  const shouldAddDummyDefaultCart = !cartListHasDefaultCart;

  return shouldAddDummyDefaultCart ? [dummyDefaultCart, ...cartList] : cartList;
};

const CartList = ({
  cartList: unfilteredCartList,
  onHomeScreenBanner,
  resetSelectedCart,
  setShowCartSelectorModal,
  showCartSelectorModal,
  updateSelectedCart,
  userEventInfo
}) => {
  const cartList = unfilteredCartList.filter(
    ({ status }) => status !== CART_STATUS.ORDER
  );
  const showCartNavigator = cartList.length > 0 && showCartSelectorModal;

  return (
    <Fragment>
      <div
        className={`flex ${
          onHomeScreenBanner ? 'text-white' : 'text-brand'
        } text-sm font-medium hidden md:flex gap-2 ms-4 relative top-0.5 self-end self-center`}
      >
        <a
          onClick={() =>
            cartList.length > 0 &&
            setShowCartSelectorModal(!showCartSelectorModal)
          }
        >
          <Fragment>
            <span className='pr-2'>Cart</span>
            {cartList.length > 0 && (
              <span className='relative top-0.5 inline-block'>
                <Image
                  src={`${staticMediaStoreBaseURL}/icons/${
                    onHomeScreenBanner
                      ? 'down-arrow-white.svg'
                      : 'down-arrow-orange.svg'
                  }`}
                  width={15}
                  height={15}
                  className={`${
                    showCartSelectorModal
                      ? 'transform rotate-180'
                      : 'transform rotate-0'
                  } ml-2`}
                  alt='downarrow'
                />
              </span>
            )}
          </Fragment>
        </a>
      </div>
      {showCartNavigator && (
        <SelectCartModal
          {...{
            cartList,
            resetSelectedCart,
            setShowCartSelectorModal,
            updateSelectedCart,
            userEventInfo
          }}
        />
      )}
    </Fragment>
  );
};

const CartView = ({
  cartItemCount,
  cartList: cartListRaw = [],
  onHomeScreenBanner,
  Router,
  selectedCart,
  setShowCartSelectorModal,
  showCartSelectorModal
}) => {
  const { authUser, resetSelectedCart, updateSelectedCart } = useUIContext();

  const cartList = getCartList(cartListRaw);

  const [{ id = '', isDefault = false } = {}] = cartList;
  const shouldRedirectToDefaultCart = cartList.length === 1 && isDefault;

  const [userEventInfo, setUserEventInfo] = useState({});
  const userEventId = getCookie({ name: COOKIE_MAP.SELECTED_USER_EVENT });
  const fetchEventData = async () => {
    if (authUser.id && userEventId) {
      const { entity } = await getUserEventDetailsById({
        userEventId,
        userId: authUser.id
      });
      setUserEventInfo(getUserEventInfoForCartSelector(entity));
    }
  };

  useEffect(() => {
    userEventId && fetchEventData();
  }, [userEventId, authUser.id]);
  return (
    <div
      onClick={() =>
        shouldRedirectToDefaultCart
          ? onClickDefaultCart({ id, Router })
          : setShowCartSelectorModal(cartList.length > 0)
      }
      className='flex relative items-center cursor-pointer text-nero rounded md:rounded-6.5 px-1 md:px-5'
    >
      <CartIcon
        {...{
          cartItemCount,
          cartList,
          onHomeScreenBanner,
          selectedCart,
          setShowCartSelectorModal,
          showCartSelectorModal
        }}
      />
      <CartList
        {...{
          cartList,
          onHomeScreenBanner,
          resetSelectedCart,
          setShowCartSelectorModal,
          showCartSelectorModal,
          updateSelectedCart,
          userEventInfo
        }}
      />
    </div>
  );
};

export default CartView;
