import { faStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CountUp from 'react-countup';

import { Heading, Paragraph } from '@/components/atomic/atoms';

const EVENTS_EXECUTED_SINCE_2018 = 11000;
const PRODUCTS_SERVICES_IN_OUR_INVENTORY = 7500;

const Achievements = () => (
  <div className='bg-snowwhite py-10 md:py-30'>
    <div className='container mx-auto block md:flex'>
      <div className='w-full max-w-68 mx-auto md:max-w-full md:w-1/2'>
        <div className='max-w-md'>
          <Paragraph
            {...{
              className: `text-xl md:text-45px leading-5 md:leading-9 font-Montserrat font-normal text-black text-center md:text-left mb-2 md:mb-4`,
              content:
                'We work with the passion to deliver amazing event experiences'
            }}
          />

          <Paragraph
            {...{
              className: `text-center md:text-left text-dim-gray leading-5 font-Montserrat text-sm md:text-lg font-semibold mt-7 md:mt-4 block`,
              content: `UAE’s highest rated biggest event service provider with the
                largest inventory from venues to catering, equipment and services!`
            }}
          />
        </div>
      </div>

      <div className='w-full max-w-68 mx-auto md:max-w-full md:w-1/2 md:flex md:flex-wrap justify-center items-center pt-4 md:pt-10'>
        <div className='block md:flex w-full space-y-3 md:space-y-0'>
          <div className='text-center w-full md:w-1/2 mx-auto'>
            <Heading
              className='text-45px md:text-7xl font-brandon text-light-coral md:mb-4 block'
              level={5}
            >
              <CountUp
                duration={5}
                end={EVENTS_EXECUTED_SINCE_2018}
                separator={','}
                suffix={'+'}
              />
            </Heading>
            <Paragraph
              {...{
                className:
                  'text-sm font-normal md:text-lg text-center capitalize',
                content: 'events since 2018'
              }}
            />
          </div>
          <div className='text-center w-full md:w-1/2 mx-auto'>
            <Heading
              className='text-45px md:text-7xl font-brandon text-light-coral md:mb-4 block'
              level={5}
            >
              <CountUp
                duration={5}
                end={PRODUCTS_SERVICES_IN_OUR_INVENTORY}
                separator={','}
                suffix={'+'}
              />
            </Heading>
            <Paragraph
              {...{
                className:
                  'text-sm font-normal md:text-lg text-center capitalize',
                content: 'products & services'
              }}
            />
          </div>
        </div>

        <div className='mt-4 md:mt-11 block w-full text-center'>
          <ul className='flex items-center justify-center w-full text-center mb-2 md:mb-4'>
            <li className='mr-2 md:mr-5 text-26px md:text-5xl text-light-coral hover:text-nero transition-all ease-in-out cursor-pointer'>
              <FontAwesomeIcon icon={faStar} />
            </li>
            <li className='mr-2 md:mr-5 text-26px md:text-5xl text-light-coral hover:text-nero transition-all ease-in-out cursor-pointer'>
              <FontAwesomeIcon icon={faStar} />
            </li>
            <li className='mr-2 md:mr-5 text-26px md:text-5xl text-light-coral hover:text-nero transition-all ease-in-out cursor-pointer'>
              <FontAwesomeIcon icon={faStar} />
            </li>
            <li className='mr-2 md:mr-5 text-26px md:text-5xl text-light-coral hover:text-nero transition-all ease-in-out cursor-pointer'>
              <FontAwesomeIcon icon={faStar} />
            </li>
            <li className='mr-0 md:mr-0 text-26px md:text-5xl text-light-coral hover:text-nero transition-all ease-in-out cursor-pointer'>
              <FontAwesomeIcon icon={faStarHalfAlt} />
            </li>
          </ul>
          <Paragraph
            {...{
              className:
                'text-sm font-normal md:text-lg text-center capitalize',
              content: 'rating & reviews'
            }}
          />
        </div>
      </div>
    </div>
  </div>
);

export default Achievements;
