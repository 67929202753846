import Image from 'next/image';
import Link from 'next/link';
import { Fragment } from 'react';

import { convertToStandardDateFormat } from '@/lib/time';

import { Button, Text } from '@/components/atomic/nuclei';
import { mediaStoreBaseURL } from '@/config/common';
import { getPageURL, ROUTE_CONFIG } from '@/services/hostPortal.service';

const EventOverview = ({
  eventId,
  eventTitle,
  eventAddress,
  eventDate,
  eventTypeName
}) => (
  <div className='flex flex-col gap-1 md:gap-2 self-center w-56 md:w-10/12'>
    <Text
      content={eventTitle || eventTypeName}
      className='text-sm md:text-xl font-semibold text-nero max-w-53 md:max-w-120 truncate'
    />
    <div className='flex flex-col md:flex-row gap-1 md:gap-3 text-2.5 md:text-sm text-nero font-normal'>
      <Text
        content={convertToStandardDateFormat(eventDate)}
        className='md:max-w-120 truncate'
      />
      <Text
        content={eventAddress}
        className='max-w-53 md:max-w-120 truncate'
      />
    </div>
    <Text
      content={`Event Id: ${eventId}`}
      className='text-2.5 md:text-sm text-silver font-normal'
    />
  </div>
);

const UserEventSummary = ({
  id: eventId,
  userEventDetails: [userEventDetail] = []
}) => {
  const {
    eventTitle,
    eventAddress,
    eventDate,
    checkoutEvent: {
      iconUrl,
      event: { name: eventTypeName }
    }
  } = userEventDetail;

  return (
    <Fragment>
      <div className='w-16 h-16 overflow-hidden rounded-lg relative'>
        <Image
          src={`${mediaStoreBaseURL}/${iconUrl}`}
          width={64}
          height={64}
          alt={eventTypeName}
          className='rounded-lg'
        />
      </div>
      <EventOverview
        {...{ eventId, eventTitle, eventAddress, eventDate, eventTypeName }}
      />
    </Fragment>
  );
};

const EventStatusButton = ({ eventStatus = 'N/A' }) => (
  <Button
    className='py-1 px-4 text-xs font-normal self-end text-brand rounded-full bg-light-red'
    width=''
  >
    {eventStatus}
  </Button>
);

const UserEventSummaryCard = ({ userEventSummaryCard }) => {
  const { id: eventId, zendeskTicketDetail } = userEventSummaryCard;
  const { inquiryToQuoteStatus: eventStatus } = zendeskTicketDetail ?? {};

  return (
    <Link
      href={getPageURL({
        pageName: ROUTE_CONFIG.EVENTS_CART_DETAILS.label,
        pathParams: {
          userEventId: eventId
        }
      })}
    >
      <div className='flex flex-col gap-2 shadow-profile px-4 pt-1 pb-3 rounded-xl cursor-pointer mb-3'>
        <EventStatusButton eventStatus={eventStatus} />
        <div className='flex flex-wrap gap-3'>
          <UserEventSummary {...{ ...userEventSummaryCard }} />
        </div>
      </div>
    </Link>
  );
};

export default UserEventSummaryCard;
