import { Text } from '@/components/atomic/nuclei';

const TermsAndConditions = ({ termsAndConditions }) => (
  <div className='border border-platinum bg-lightgray p-4 md:p-6 rounded-md'>
    <Text
      {...{
        className: 'font-medium text-base mb-4',
        content: 'Terms & Conditions'
      }}
    />
    <ol className='flex flex-col gap-2 list-decimal pl-6'>
      {termsAndConditions.map((label, index) => (
        <Text
          key={index}
          {...{
            className: 'font-light text-sm md:text-base',
            content: label,
            HtmlTag: 'li'
          }}
        />
      ))}
    </ol>
  </div>
);

export default TermsAndConditions;
