import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';
import { useRef, useState } from 'react';

import { ImageCarouselModal } from '@/components/atomic/atoms';
import { Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import { transformImagesForGridAndCarousel } from '@/helpers/carousel';
import { getEventGalleryRouteLabel } from '@/services/homePage.service';
import { getPageURL } from '@/services/hostPortal.service';

const EventGalleryMobile = ({ eventCategory, eventGallery, title }) => {
  const navRef = useRef(null);
  const progressBarRef = useRef(null);
  const [showImageModal, setShowimageModal] = useState(false);
  const eventGalleryTranslation = useTranslation('eventGallery');
  const eventTitle = eventGalleryTranslation.t(title);
  const openImageModal = (image) => {
    setShowimageModal(image);
  };

  const handleScroll = () => {
    const computedScroll =
      100 *
      (navRef.current.scrollLeft /
        (navRef.current.scrollWidth - navRef.current.clientWidth));
    progressBarRef.current.style.width =
      computedScroll > 20 ? `${computedScroll}%` : '20%';
  };

  const eventGalleryImagesTransformed = transformImagesForGridAndCarousel(
    eventGallery?.eventCategory.images || []
  );

  const galleryPageURL = getPageURL({
    pageName: getEventGalleryRouteLabel({ eventCategory })
  });

  return (
    <section id='event-gallery'>
      <div className='relative bg-platinum pt-7'>
        <div className='max-w-40 mx-auto block'>
          <Text
            {...{
              className:
                'font-Inter font-bold text-2xl leading-6 text-nero text-center',
              content: eventTitle,
              HtmlTag: 'h3'
            }}
          />
        </div>

        <div className='w-14 bg-dim-gray h-0.5 mx-auto mt-3 mb-5'>
          <div
            className='bg-control h-0.5 w-1/5'
            ref={progressBarRef}
          ></div>
        </div>

        <div className='px-2.5 xxxs:px-5'>
          <div
            className='flex justify-start items-center flex-nowrap overflow-x-scroll no-scrollbar space-x-4 pb-5'
            onScroll={handleScroll}
            ref={navRef}
          >
            {eventGalleryImagesTransformed.map(
              (image, index) =>
                index < 7 && (
                  <div
                    key={image.id}
                    onClick={() => openImageModal(image)}
                    className={
                      'relative cursor-pointer rounded-lg overflow-hidden max-h-47 w-full min-w-65 h-full min-h-45 bg-cover bg-no-repeat'
                    }
                    style={{
                      backgroundImage: `url('${staticMediaStoreBaseURL}${image.imageUrl}')`
                    }}
                  >
                    {
                      <div className='rounded-lg w-full h-46 items-center justify-center hidden'>
                        <div className='z-20 text-white text-center'>
                          <Text
                            {...{
                              className:
                                'text-15px leading-4 font-Inter font-semibold max-w-28 mx-auto',
                              content: image.eventName
                            }}
                          />
                        </div>
                      </div>
                    }
                  </div>
                )
            )}
            <div className='ps-4 pe-12 block'>
              <Link href={galleryPageURL}>
                <div className='text-center max-w-18 mx-auto'>
                  <div className='bg-more-blog h-17 w-17 rounded-full flex justify-center items-center cursor-pointer shadow-search'>
                    <div className='relative h-4'>
                      <Image
                        alt='View All'
                        height={15}
                        src={`${staticMediaStoreBaseURL}/icons/right-white.svg`}
                        width={21}
                      />
                    </div>
                  </div>
                  <Text
                    {...{
                      className:
                        'text-nero font-medium font-Inter text-xs leading-4 mt-3',
                      content: 'View All',
                      HtmlTag: 'span'
                    }}
                  />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {showImageModal && (
        <ImageCarouselModal
          {...{
            images: eventGalleryImagesTransformed,
            modalImage: showImageModal,
            openImageModal
          }}
        />
      )}
    </section>
  );
};

export default EventGalleryMobile;
