import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';

import { Heading, Paragraph } from '@/components/atomic/atoms';
import { Button } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import {
  captureGTMEventCTAClick,
  GTM_EVENTS_CTA_NAME,
  PAGE_NAME
} from '@/lib/gtm';
import { getPageURL, ROUTE_CONFIG } from '@/services/hostPortal.service';

const WhyHafla = ({ eventId }) => {
  const [isHoveredOnCta, setIsHoveredOnCta] = useState(false);
  const [isFocusedOnCta, setIsFocusedOnCta] = useState(false);

  return (
    <div className='bg-white py-10 md:py-20'>
      <div className='container mx-auto'>
        <div className='max-w-75 mx-auto block mb-10'>
          <Heading
            className='text-2xl md:text-45px text-nero text-center leading-16 mb-2 md:mb-3'
            type='h3'
          >
            WHY HAFLA?
          </Heading>
          <Paragraph
            {...{
              className: 'text-center text-sm md:text-base font-medium',
              content: `We are the world's first AI-powered event organising platform`
            }}
          />
        </div>

        <div className='max-w-68 md:max-w-194 mx-auto block'>
          <div className='block md:grid md:grid-flow-col md:grid-cols-3 mb-7 md:mb-14'>
            <div className='flex text-center md:block md:max-w-42 mx-auto space-x-5 md:space-x-0 mb-3 md:mb-0'>
              <Image
                alt='why hafla icons'
                className='block text-center mx-auto max-w-15 md:max-w-28'
                height={111}
                layout='fixed'
                src={`${staticMediaStoreBaseURL}/icons/option-matching.svg`}
                width={112}
              />
              <Paragraph
                {...{
                  className:
                    'text-left md:text-center font-brandon text-sm md:text-base mt-1.5 md:mt-9 block',
                  content: 'GET PERSONALISED OPTIONS MATCHING YOUR IDEAS'
                }}
              />
            </div>
            <div className='flex text-center md:block md:max-w-42 mx-auto space-x-5 md:space-x-0 mb-3 md:mb-0'>
              <Image
                alt='why hafla icons'
                className='block text-center mx-auto max-w-15 md:max-w-28'
                height={111}
                layout='fixed'
                src={`${staticMediaStoreBaseURL}/icons/organise-place.svg`}
                width={112}
              />
              <Paragraph
                {...{
                  className:
                    'text-left md:text-center font-brandon text-sm md:text-base mt-1.5 md:mt-9 block',
                  content: 'IDEATE, COLLABORATE, AND ORGANISE AT ONE PLACE'
                }}
              />
            </div>
            <div className='flex text-center md:block md:max-w-42 mx-auto space-x-5 md:space-x-0 mb-3 md:mb-0'>
              <Image
                alt='why hafla icons'
                className='block text-center mx-auto max-w-15 md:max-w-28'
                height={111}
                layout='fixed'
                src={`${staticMediaStoreBaseURL}/icons/payments.svg`}
                width={112}
              />
              <Paragraph
                {...{
                  className:
                    'text-left md:text-center font-brandon text-sm md:text-base mt-1.5 md:mt-9 block',
                  content: 'MANAGE LOGISTICS AND PAYMENTS WITH A FEW CLICKS'
                }}
              />
            </div>
          </div>

          <Heading
            type='h3'
            className='text-22px md:text-4xl text-nero text-center leading-5 md:leading-16'
          >
            WANT TO KNOW US BETTER?
          </Heading>

          <div className='block md:flex w-full mt-4 md:mt-10 md:space-x-7 space-y-3 md:space-y-0'>
            <Link
              href={getPageURL({
                pageName: ROUTE_CONFIG.EVENTS_LEAD_GENERATION_FORM.label,
                pathParams: {
                  id: eventId
                }
              })}
            >
              <a
                onClick={() => {
                  captureGTMEventCTAClick({
                    ctaName: GTM_EVENTS_CTA_NAME.CONSULT_EVENT_EXPERT,
                    pageName: PAGE_NAME.EVENT_LEAD_GEN.label
                  });
                }}
              >
                <Button
                  className={`text-xs md:text-base text-white font-brandon p-2.5 md:p-5 bg-b2b-btn hover:bg-b2b-hover hover:text-black focus:text-black rounded-md block`}
                >
                  SCHEDULE FREE CONSULTATION
                </Button>
              </a>
            </Link>

            <a
              className='w-full block h-full group'
              download
              href={`${staticMediaStoreBaseURL}/files/company-profile.pdf`}
              onBlur={() => setIsFocusedOnCta(false)}
              onFocus={() => setIsFocusedOnCta(true)}
              onMouseLeave={() => setIsHoveredOnCta(false)}
              onMouseOver={() => setIsHoveredOnCta(true)}
              rel='noopener noreferrer'
              target='_blank'
            >
              <Button
                className={`w-full text-xs md:text-base text-white font-brandon p-2.5 md:p-5 bg-b2b-btn hover:bg-b2b-hover hover:text-black focus:text-black rounded-md`}
              >
                <span
                  className={`me-3 ${
                    isHoveredOnCta || isFocusedOnCta ? 'animate-bounce' : ' '
                  }`}
                >
                  {isHoveredOnCta || isFocusedOnCta ? (
                    <Image
                      alt='Download Brochure'
                      height={18}
                      src={`${staticMediaStoreBaseURL}/icons/download.svg`}
                      width={18}
                    />
                  ) : (
                    <Image
                      alt='Download Brochure'
                      height={18}
                      src={`${staticMediaStoreBaseURL}/icons/download-white.svg`}
                      width={18}
                    />
                  )}
                </span>
                DOWNLOAD BROCHURE
              </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyHafla;
