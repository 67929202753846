import Image from 'next/image';
import { useRouter } from 'next/router';

import { RibbonButton } from '@/components/atomic/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';
import {
  captureGTMEventCTAClick,
  GTM_EVENTS_CTA_NAME,
  PAGE_NAME
} from '@/lib/gtm';

const SectionActionButton = ({
  buttonText,
  isGTMEventCTAClick = false,
  isOpenInSameTab = false,
  ribbonButtonClass = 'ribbon-button-styles',
  url
}) => {
  const Router = useRouter();
  return (
    <div className='flex flex-row items-center justify-center mb-10'>
      <div className='flex flex-row justify-content-center place-content-center'>
        <RibbonButton
          className={`flex flex-nowrap rounded-full text-10px uppercase tracking-wider ml-0 px-2 font-google-basic font-normal h-11 ${ribbonButtonClass}`}
          onClick={() => {
            isGTMEventCTAClick &&
              captureGTMEventCTAClick({
                ctaName: GTM_EVENTS_CTA_NAME.PLAN_YOUR_EVENT,
                pageName: PAGE_NAME.HOME_PAGE.label
              });
            return isOpenInSameTab
              ? Router.push(url)
              : window.open(url, '_blank');
          }}
        >
          {buttonText}
          <Image
            alt='arrow icon'
            className='self-center place-self-center transform rotate-180 pl-5'
            height={4}
            layout='fixed'
            src={`${staticMediaStoreBaseURL}/icons/back.svg`}
            width={6}
          />
        </RibbonButton>
      </div>
    </div>
  );
};

export default SectionActionButton;
