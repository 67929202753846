import Image from 'next/image';
import { useRouter } from 'next/router';

import { SectionHeading } from '@/components/atomic/atoms';
import { Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import { captureGTMEventBrowseCategoriesClick } from '@/lib/gtm';
import { featuredCategories } from '@/services/homePage.service';

const FeaturedCategoriesMobile = () => {
  const router = useRouter();
  if (!router.isReady) {
    return <div>Loading...</div>;
  }
  return (
    <div className='py-10 px-4'>
      <SectionHeading
        {...{
          letterSpacing: '3px',
          title: ' Explore Services',
          underline: true
        }}
      />
      <div className='grid grid-cols-2 gap-4 pt-8'>
        {featuredCategories.map(({ href, logoUrl, name }, id) => (
          <a
            href={href}
            key={id}
            onClick={() => captureGTMEventBrowseCategoriesClick({ name })}
          >
            <div className='flex flex-col items-center overflow-hidden gap-2'>
              <div className='w-full h-full relative shadow'>
                <Image
                  alt={name}
                  className='rounded-lg'
                  height={110}
                  layout='responsive'
                  objectFit='cover'
                  src={`${staticMediaStoreBaseURL}${logoUrl}`}
                  width={100}
                />
              </div>
              <div className='w-full'>
                <Text
                  {...{
                    className: 'font-Montserrat font-semibold',
                    content: name
                  }}
                />
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default FeaturedCategoriesMobile;
