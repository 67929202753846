import Head from 'next/head';
import { useState } from 'react';

import { Footer, ProgressBar } from '@/components/atomic/atoms';
import {
  Banner,
  DesktopHomeBanner,
  MobileHomeBanner
} from '@/components/atomic/molecules';
import { useUIContext } from '@/components/context/uiContext/ManagedUiContext';

const DefaultLayoutOrganism = ({ paramData, children }) => {
  const [direction, setDirection] = useState('ltr');
  const { surface } = useUIContext();

  const changeDirection = () => {
    setDirection((prevState) => {
      if (prevState === 'rtl') {
        setDirection('ltr');
      } else {
        setDirection('rtl');
      }
    });
  };
  const surfaceBanners = {
    mobile: MobileHomeBanner,
    web: DesktopHomeBanner
  };

  const FinalBanner = paramData.homeScreen
    ? surfaceBanners[surface]
    : () => (
        <Banner
          eventId={paramData.eventCategory}
          changeDirection={changeDirection}
          translate={paramData.bannerTranslate}
          homeScreen={paramData.homeScreen}
        />
      );

  return (
    <>
      <Head>
        <title>{paramData.bannerTranslate.t('meta-title')}</title>
        <meta
          name='description'
          content={paramData.bannerTranslate.t('meta-description')}
        />
        <meta
          property='og:title'
          content={paramData.bannerTranslate.t('meta-title')}
        />
        <meta
          property='og:description'
          content={paramData.bannerTranslate.t('meta-description')}
        />
      </Head>
      <div dir={direction}>
        <ProgressBar />
        {!paramData.hideBanner && <FinalBanner />}
        <main className='w-full overflow-hidden'>{children}</main>
        <Footer />
        {paramData.homeScreen && <div className='md:hidden h-14'></div>}
      </div>
    </>
  );
};

export default DefaultLayoutOrganism;
