import { Fragment } from 'react';

import {
  AdditionalChargeHostCart,
  DeliveryChargeHostCart,
  DiscountsHostCart,
  ItemTotalHostCart,
  LumpSumDiscountHostCart,
  NonTaxableCharge,
  OrderTotalHostCart,
  PreVatPriceHostCart,
  PromoHostCart,
  TotalItemsPriceHostCart,
  VatAmountHostCart
} from '@/components/atomic/atoms';

import renderItem from '@/helpers/renderItem';

const SummaryFieldsForHostCartCheckout = [
  TotalItemsPriceHostCart,
  DiscountsHostCart,
  ItemTotalHostCart,
  DeliveryChargeHostCart,
  AdditionalChargeHostCart,
  PromoHostCart,
  LumpSumDiscountHostCart,
  PreVatPriceHostCart,
  VatAmountHostCart,
  NonTaxableCharge,
  OrderTotalHostCart
];

const SummaryFields = (context) => (
  <Fragment>
    {SummaryFieldsForHostCartCheckout.map(renderItem(context))}
  </Fragment>
);

export default SummaryFields;
