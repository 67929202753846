export const emailFestivalFields = [
  {
    label: 'Contact Email',
    dbKeyName: 'eventContactEmail',
    type: 'email'
  },
  {
    label: 'Event Title',
    dbKeyName: 'eventTitle',
    type: 'text'
  }
];

export const emailField = [
  {
    label: 'Contact Email',
    dbKeyName: 'eventContactEmail',
    type: 'email'
  }
];
