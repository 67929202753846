import Image from 'next/image';

import { Text, TextKeyValuePair } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';

const EventCartOrderDetails = ({
  userEventCartOrderDetailsBanner: { eventName, trnNumber },
  userEventCartOrderDetailsBody
}) => (
  <div className='flex flex-col gap-3 bg-lightgray border-b border-r rounded-b-lg border-l border-platinum text-rich-black md:p-6 p-4'>
    <TextKeyValuePair
      {...{
        className: 'flex-col',
        label: eventName.value,
        labelClass: 'text-base md:text-xl font-medium text-rich-black',
        spaceTop: 'gap-1 md:gap-2',
        value: trnNumber.value ? `TRN: ${trnNumber.value}` : '',
        valueClassName: 'text-xs md:text-base text-dim-gray font-medium'
      }}
    />
    <div className={`flex flex-col md:flex-row justify-between`}>
      <div className='grid grid-cols-1 md:grid-cols-4 md:grid-rows-2 gap-2 mt-2 md:mt-0'>
        {userEventCartOrderDetailsBody.map((field) => {
          const { alt, className, icon, sortOrder, value } = field;
          return (
            <div
              key={sortOrder}
              className={`flex gap-3 md:gap-2 md:w-88 ${className}`}
            >
              <div className='flex'>
                <Image
                  alt={alt}
                  height={20}
                  src={`${staticMediaStoreBaseURL}/icons/${icon}`}
                  width={20}
                />
              </div>
              <Text
                {...{
                  content: value,
                  className:
                    'flex self-center text-sm md:text-base font-light max-w-52 truncate'
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  </div>
);

export default EventCartOrderDetails;
