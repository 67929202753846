import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import validator from 'validator';

// TODO: Viresh: - use Datepicker atom here https://bitbucket.org/evinops-hafla/hafla/pull-requests/4166#comment-400319651

import {
  CheckoutActionButton,
  CheckoutDisclaimerModal,
  EventDateTime,
  RequestCallModal
} from '@/components/atomic/atoms';
import {
  CheckoutEventDetail,
  CheckoutUserInfo,
  ToastContainer
} from '@/components/atomic/molecules';
import { useUIContext } from '@/components/context/uiContext/ManagedUiContext';
import { useIsMobile } from '@/helpers/screenResolutionHelper';
import { captureGTMEventAddShippingInfo } from '@/lib/gtm';
import {
  createDateWithTime,
  currentDate,
  formatTime,
  getDurationBetweenDates,
  parseDateWithMoment
} from '@/lib/time';
import inputValidationConfig from '@/lib/validations/input-validations';
import {
  defaultCartCheckout,
  getCheckoutEventsAPI
} from '@/services/hostCart.service';
import { getPageURL, ROUTE_CONFIG } from '@/services/hostPortal.service';
import { getAuth } from '@/services/identity.service';
import { sendPriorityEventInquiryDetails } from '@/services/inquiry.service';

const userAddressObject = {
  buildingName: '',
  cityId: '',
  formattedAddress: '',
  houseNumber: '',
  landmark: '',
  latitude: 0,
  locationSelected: false,
  longitude: 0
};

const CheckoutEventContactInfoOrganism = ({
  currentStep,
  setCurrentStep,
  setStepReached,
  stepReached
}) => {
  const { id: cartId, cartItems, defaultHostCartDetail } = useUIContext();
  const [isMobile] = useIsMobile();
  const Router = useRouter();
  const [eventDateTime, setEventDateTime] = useState('');
  const [showToast, setShowToast] = useState({
    message: '',
    show: false,
    status: false
  });

  const [contactInfo, setContactInfo] = useState({});

  const [eventTime, setEventTime] = useState('');

  const [userAddress, setUserAddress] = useState(userAddressObject);
  const [showEventDateError, setShowEventDateError] = useState(false);
  const [showEventTimeError, setShowEventTimeError] = useState(false);
  const [showEventTypeError, setShowEventTypeError] = useState(false);
  const [showFullNameError, setShowFullNameError] = useState(false);
  const [showAddressError, setShowAddressError] = useState(false);
  const [showEmailError, setEmailError] = useState(false);
  const [eventDetails, setEventDetails] = useState();
  const [eventId, setEventId] = useState('');
  const [isReqCallBackApplicable, setIsReqCallBackApplicable] = useState(false);

  const [isReqCallBackModalVisible, setIsReqCallBackModalVisible] =
    useState(false);
  const [showDisclaimerModal, setShowDisclaimerModal] = useState(false);
  const [checkoutEventId, setCheckoutEventId] = useState('');

  const {
    clearErrors,
    control,
    formState: { errors },
    getValues,
    register,
    setValue
  } = useForm();

  useEffect(() => {
    if (eventDateTime && eventTime) {
      const formattedEventDateTime = parseDateWithMoment(
        createDateWithTime(eventDateTime, eventTime)
      );
      const duration = getDurationBetweenDates({
        date: formattedEventDateTime,
        referenceDate: currentDate
      });
      const reqCallbackVisibility = duration.asMinutes() <= 24 * 60;
      setIsReqCallBackApplicable(reqCallbackVisibility);
      reqCallbackVisibility &&
        setIsReqCallBackModalVisible(!isReqCallBackModalVisible);
    }
  }, [eventDateTime, eventTime]);

  const handleEventDateTimeChange = (e) => {
    setEventDateTime(e);
    setShowEventDateError(false);
  };

  const handleEventTime = (e) => {
    setEventTime(e.target.value);
    setShowEventTimeError(false);
  };

  const createPriorityLeadData = async (callback) => {
    const cartItemList =
      cartItems?.length > 0
        ? cartItems.map(({ product, quantity }) => ({ product, quantity }))
        : [];

    const formattedEventTime = formatTime(eventTime);

    const auth = getAuth();
    return callback({
      cartItems: cartItemList,
      eventDetails: {
        eventDate: eventDateTime,
        eventTime: formattedEventTime
      },
      userId: auth.id
    });
  };

  const handleReqCallBackClick = async () => {
    const { message, status } = await createPriorityLeadData(
      sendPriorityEventInquiryDetails
    );
    setIsReqCallBackModalVisible(false);
    if (status) {
      Router.push(
        getPageURL({
          pageName: ROUTE_CONFIG.CHECKOUT_CALLBACK_SUCCESS.label
        })
      );
    } else {
      setShowToast({
        message:
          message || 'Failed to create priority lead. Please try again later.',
        show: true,
        status: false
      });
    }
  };

  const validateUserInputs = () => {
    const validateContactName =
      !inputValidationConfig.hostNameValidationConfig.nameRegex.test(
        contactInfo?.name || ''
      );
    const invalidEmail = !validator.isEmail(contactInfo?.email || '');
    if (
      !getValues('checkoutEventId') ||
      !eventDateTime ||
      !eventTime ||
      !contactInfo?.name?.trim() ||
      invalidEmail ||
      !userAddress.formattedAddress
    ) {
      setShowEventTypeError(!getValues('checkoutEventId'));
      setShowEventDateError(!eventDateTime);
      setShowEventTimeError(!eventTime);
      setShowFullNameError(validateContactName);
      setEmailError(invalidEmail);
      setShowAddressError(!userAddress.formattedAddress);
      return true;
    }

    return false;
  };
  const handleEventInfoSubmit = async () => {
    if (validateUserInputs()) return;
    const auth = getAuth();

    const checkoutDetails = await defaultCartCheckout({
      params: {
        cartId,
        userCartId: auth.id
      },
      body: {
        userEventDetails: {
          checkoutEventId: getValues('checkoutEventId'),
          eventAddress: userAddress,
          eventContactEmail: contactInfo?.email,
          eventContactName: contactInfo?.name,
          eventDate: eventDateTime,
          eventStartTime: eventTime
        }
      }
    });
    const {
      cartItems: itemList,
      derivedValues: { orderTotal },
      promoCode: { code } = {}
    } = defaultHostCartDetail;

    if (checkoutDetails.status) {
      userAddress &&
        captureGTMEventAddShippingInfo({
          code,
          itemList,
          orderTotal
        });

      if (isMobile) {
        setCurrentStep(currentStep + 1);
        setStepReached(stepReached + 1);
      }
      Router.replace(checkoutDetails.entity.checkoutUrl);
    }
  };

  const handleEventSelect = (checkoutEvent) => {
    setEventId(checkoutEvent.event.id);
    setCheckoutEventId(checkoutEvent.id);
    setShowEventTypeError(false);
  };

  const moveSelectedEventToTop = (eventDetailsArray, selectedEventId) => {
    for (let i = 0; i < eventDetailsArray.length; i++) {
      if (eventDetailsArray[i].id === selectedEventId) {
        const selectedEvent = eventDetailsArray[i];
        eventDetailsArray.splice(i, 1);
        eventDetailsArray.unshift(selectedEvent);
        break;
      }
    }
    return eventDetailsArray;
  };
  const showCheckoutForm = async () => {
    const events = await getCheckoutEventsAPI();
    setEventDetails(moveSelectedEventToTop(events.entity, checkoutEventId));
    return events;
  };

  useEffect(() => {
    showCheckoutForm();
  }, [currentStep]);

  return (
    <div className='sm:p-8'>
      <ToastContainer {...{ showToast, setShowToast }} />
      <CheckoutEventDetail
        {...{
          errors,
          setValue,
          getValues,
          register,
          control,
          clearErrors,
          eventDetails,
          handleEventSelect,
          eventId,
          showEventTypeError
        }}
      />
      <EventDateTime
        {...{
          eventDateTime,
          eventTime,
          handleEventDateTimeChange,
          handleEventTime,
          isMobile,
          showEventDateError,
          showEventTimeError
        }}
      />
      <CheckoutUserInfo
        {...{
          contactInfo,
          currentStep,
          setContactInfo,
          setCurrentStep,
          setEmailError,
          setShowAddressError,
          setShowFullNameError,
          setStepReached,
          setUserAddress,
          showAddressError,
          showEmailError,
          showFullNameError,
          stepReached,
          userAddress,
          userAddressObject
        }}
      />
      {showDisclaimerModal && (
        <CheckoutDisclaimerModal {...{ setShowDisclaimerModal }} />
      )}
      {isReqCallBackModalVisible && (
        <RequestCallModal
          {...{
            handleReqCallBackClick,
            isReqCallBackModalVisible,
            setIsReqCallBackModalVisible
          }}
        />
      )}
      <CheckoutActionButton
        {...{
          handleEventInfoSubmit,
          handleReqCallBackClick,
          isReqCallBackApplicable
        }}
      />
    </div>
  );
};

export default CheckoutEventContactInfoOrganism;
