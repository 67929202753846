import { useEffect, useState } from 'react';

import {
  AddAddressButton,
  AddressFormContainer,
  ContactDetailInputFields,
  DeliveryAddress,
  ShowFormattedAddress
} from '@/components/atomic/atoms';
import { BottomModal, InputLabel } from '@/components/atomic/nuclei';
import inputValidationConfig from '@/lib/validations/input-validations';
import { getCityDetails } from '@/services/city.service';

const AddressModal = ({
  addAddressToggler,
  cityList,
  setUserAddress,
  userAddress
}) => (
  <BottomModal
    paramData={{ setCloseModal: addAddressToggler, showHeader: false }}
  >
    <AddressFormContainer
      {...{
        addAddressToggler,
        cityList,
        setUserAddress,
        userAddress
      }}
    />
  </BottomModal>
);

const CheckoutUserInfo = ({
  contactInfo,
  currentStep,
  setContactInfo,
  setEmailError,
  setShowAddressError,
  setShowFullNameError,
  setUserAddress,
  showAddressError,
  showEmailError,
  showFullNameError,
  userAddress
}) => {
  const [showAddAddress, setShowAddAddress] = useState(false);
  const [cityList, setCityList] = useState([]);

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.name === 'name') setShowFullNameError(false);
    if (e.target.name === 'email') setEmailError(false);

    setContactInfo({ ...contactInfo, [e.target.name]: e.target.value });
  };

  const addAddressToggler = () => {
    setShowAddressError(false);
    setShowAddAddress((prevState) => !prevState);
  };

  const gettingCityDetails = async () => {
    const cities = await getCityDetails();
    setCityList(cities.entity);
  };

  useEffect(() => {
    gettingCityDetails();
  }, [currentStep]);

  const inputFields = [
    {
      defaultValue: contactInfo?.name || '',
      errorField: showFullNameError,
      errorMessage:
        inputValidationConfig.hostNameValidationConfig.nameInputError,
      name: 'name',
      placeholder: 'Full Name'
    },
    {
      defaultValue: contactInfo?.email || '',
      errorField: showEmailError,
      errorMessage: 'Please enter a valid email address.',
      name: 'email',
      placeholder: 'Email'
    }
  ];

  return (
    <form>
      <div className='pt-5 md:pt-8'>
        <div className='pb-5 md:pb-6'>
          <InputLabel
            required
            className='block text-sm md:text-base font-Montserrat text-nero font-medium mb-4'
          >
            Contact Details
          </InputLabel>
          <ContactDetailInputFields
            {...{ inputFields, onChange: handleChange }}
          />
        </div>

        <div
          className='pb-5 md:pb-6'
          id='contact-address'
        >
          <DeliveryAddress {...{ showAddressError }} />
          {!userAddress.formattedAddress && (
            <AddAddressButton {...{ setShowAddAddress }} />
          )}
          {userAddress.formattedAddress && (
            <ShowFormattedAddress
              {...{
                setShowAddAddress,
                userAddress
              }}
            />
          )}
        </div>
        {showAddAddress && (
          <AddressModal
            {...{
              addAddressToggler,
              cityList,
              setUserAddress,
              userAddress
            }}
          />
        )}
      </div>
    </form>
  );
};

export default CheckoutUserInfo;
