import {
  FormValidationErrorMessage,
  InputLabel,
  SelectFormInput
} from '@/components/atomic/nuclei';

const CheckoutEventDetail = ({
  clearErrors,
  control,
  errors,
  eventDetails,
  getValues,
  register,
  setValue,
  showEventTypeError
}) => {
  const options = eventDetails?.map((events) => ({
    id: events.id,
    name: events.event.name
  }));
  return (
    <>
      <div className='block md:flex md:items-center md:mb-4'>
        <div>
          <InputLabel
            {...{
              className:
                'block text-sm md:text-base font-Montserrat text-nero font-medium',
              children: 'Choose the event you are planning for'
            }}
          />
        </div>
        <div className='md:ms-3 pb-1.5'>
          {showEventTypeError && (
            <FormValidationErrorMessage
              {...{ message: 'Please choose event type.' }}
            />
          )}
        </div>
      </div>
      <div className='w-full relative mt-4 md:mt-0 checkout-event-type'>
        {Boolean(eventDetails?.length) && (
          <SelectFormInput
            {...{
              clearErrors,
              control,
              dbName: 'checkoutEventId',
              disabled: false,
              errors,
              getValues,
              name: 'Checkout Event Id',
              placeholderLabel: 'Event Type',
              register,
              setValue,
              values: options
            }}
          />
        )}
      </div>
    </>
  );
};

export default CheckoutEventDetail;
