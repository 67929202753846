import Image from 'next/image';

import { PriceNotes } from '@/components/atomic/atoms';
import { SummaryFields } from '@/components/atomic/molecules';
import { mediaStoreBaseURL, staticMediaStoreBaseURL } from '@/config/common';
import { itemImageDefault } from '@/helpers/carousel';
import {
  formatToTwoDecimalString,
  parseFormatPriceValueFromAPI
} from '@/lib/numberStringUtils';

const MobileOrderSummaryOrganism = ({
  derivedValues: {
    additionalChargeAmount: additionalCharge,
    deliveryChargeAmount: deliveryCharge,
    listedDiscountAmount,
    listedPriceAmount: itemTotal,
    orderTotal,
    promoCode: promoCodeDetails,
    sellingPriceAmount: listedPriceAmount,
    vatAmount
  },
  externalNotes,
  orderItems: cartOrderItems = [],
  promoCodeDiscountAmount,
  setShowOrderSummary,
  showOrderSummary
}) => (
  <div className='block bg-lightpink'>
    <div className='h-10 box-border bg-lightpink border-white border-b-2 px-5 py-3 flex items-center justify-between'>
      <div
        className='flex items-center'
        onClick={() => setShowOrderSummary((prevValue) => !prevValue)}
      >
        <span className='text-nero text-sm font-medium leading-5 me-3'>
          Order Summary
        </span>
        <Image
          src={`${staticMediaStoreBaseURL}/icons/down-arrow.svg`}
          alt='Up Arrow'
          height={8}
          width={8}
          className={`cursor-pointer ${
            showOrderSummary ? 'transform rotate-180' : ''
          }`}
        />
      </div>

      <span className='text-nero text-sm font-medium leading-5'>
        AED {formatToTwoDecimalString({ value: orderTotal || null })}
      </span>
    </div>

    {showOrderSummary ? (
      <>
        <div className='overflow-auto custom-scroll'>
          {cartOrderItems.map((cartItem, index) => {
            const localProductImageUrl =
              cartItem.product.productMedia &&
              cartItem.product.productMedia.length
                ? `${mediaStoreBaseURL}/${cartItem.product.productMedia[0].media.url}`
                : itemImageDefault.url;

            return (
              <div
                className='w-full box-border bg-lightpink px-5'
                key={index}
              >
                <div className='border-b border-white flex py-3'>
                  <div
                    className='h-15.5 max-w-25 w-full flex-none bg-cover text-center overflow-hidden rounded-sm md:rounded me-3'
                    style={{ backgroundImage: `url(${localProductImageUrl})` }}
                  ></div>
                  <div className='w-full bg-transparent flex flex-col justify-between leading-normal'>
                    <div className='text-nero text-xs font-medium leading-4'>
                      <div className='max-w-30 w-full'>
                        <h5 className='font-Montserrat'>
                          {cartItem && cartItem.product
                            ? cartItem.product.name
                            : ''}
                        </h5>
                      </div>
                      <div className='flex justify-between text-10px font-medium leading-4'>
                        <div>
                          <p className='capitalize'>
                            {' '}
                            qty: <span> {cartItem.quantity} </span>{' '}
                          </p>
                        </div>
                        <div>
                          <p className='text-nero'>
                            AED {cartItem.derivedValues.unitSellingPrice}
                          </p>
                          {cartItem.derivedValues.listedDiscount ? (
                            <span className='text-dim-gray line-through'>
                              AED{' '}
                              {parseFormatPriceValueFromAPI(
                                cartItem.derivedValues.listedDiscount
                              )}
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className='px-5 bg-lightpink'>
          <SummaryFields
            {...{
              additionalCharge,
              cartItems: cartOrderItems,
              itemTotal,
              deliveryCharge,
              orderTotal,
              promoCodeDetails,
              promoCodeDiscountAmount,
              listedPriceAmount,
              listedDiscountAmount,
              vatAmount,
              cartOrderScreenType: 'cart'
            }}
          />
        </div>
        <div>
          <PriceNotes {...{ cart: { externalNotes } }} />
        </div>
      </>
    ) : (
      ''
    )}
  </div>
);

export default MobileOrderSummaryOrganism;
