import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { HeaderContainer } from '@/components/atomic/molecules';
import { Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import { getPageURL, ROUTE_CONFIG } from '@/services/hostPortal.service';

const CheckoutHeaderOrganism = ({ checkoutParams, heading }) => {
  const Router = useRouter();
  const handleBack = () => {
    if (
      checkoutParams &&
      checkoutParams.isCheckoutFlow &&
      Router.query.status === 'payment-failed'
    ) {
      if (checkoutParams.currentStep < 3) {
        Router.back();
        return;
      }
      checkoutParams.setShowpaymentFailed('');
      checkoutParams.setCurrentStep(2);
      checkoutParams.setStepReached(2);
    } else {
      Router.back();
    }
  };

  return (
    <HeaderContainer>
      <div className='md:flex hidden items-center w-3/5'>
        <Link
          className='cursor-pointer'
          href={getPageURL({
            pageName: ROUTE_CONFIG.HOME_PAGE.label
          })}
        >
          <a>
            <Image
              alt='Hafla'
              className='inline-block hafla-header-img md:26px cursor-pointer'
              height={32}
              layout='fixed'
              src={`${staticMediaStoreBaseURL}/logos/hafla_new.svg`}
              width={99}
            />
          </a>
        </Link>
      </div>
      <div className='flex md:hidden items-center w-3/5'>
        <Image
          alt='backIcon'
          className='inline-block hafla-header-back cursor-pointer'
          height={18}
          layout='fixed'
          onClick={handleBack}
          src={`${staticMediaStoreBaseURL}/icons/back-angle-black.svg`}
          width={10}
        />
        <Text
          {...{
            className: `ms-5 text-sm w-full h-full overflow-hidden ${
              checkoutParams?.isCheckoutFlow ? 'font-medium' : 'font-bold'
            }`,
            content: heading
          }}
        />
      </div>
    </HeaderContainer>
  );
};

export default CheckoutHeaderOrganism;
