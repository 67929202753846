import { CartNotes } from '@/components/atomic/atoms';
import { SummaryFields } from '@/components/atomic/molecules';
import { Text } from '@/components/atomic/nuclei';
import { useUIContext } from '@/components/context/uiContext/ManagedUiContext';
import { mediaStoreBaseURL } from '@/config/common';
import { itemImageDefault } from '@/helpers/carousel';
import { parseFormatPriceValueFromAPI } from '@/lib/numberStringUtils';
import {
  filterItemsByCartItemGroupType,
  getItemPrice
} from '@/services/hostCart.service';

const CheckoutCartItemList = ({ NRFQItems }) =>
  NRFQItems.map((itemInfo, index) => {
    const productImageUrl = itemInfo?.product?.productMedia?.length
      ? `${mediaStoreBaseURL}/${itemInfo.product.productMedia[0].media.url}`
      : itemImageDefault.url;

    return (
      <div
        className='w-full flex mb-3 pb-3 box-border border-b border-lightpink'
        key={index}
      >
        <div
          className='h-18.5 md:w-30 flex-none text-center overflow-hidden rounded-sm md:rounded bg-no-repeat bg-contain bg-center'
          style={{ backgroundImage: `url(${productImageUrl})` }}
        ></div>

        <div className='w-full bg-white px-4 flex flex-col justify-between leading-normal'>
          <div className='text-nero text-sm font-medium leading-5'>
            <Text
              {...{
                className: 'font-Montserrat pb-2',
                content:
                  itemInfo && itemInfo.product ? itemInfo.product.name : '',
                HtmlTag: 'h5'
              }}
            />
            <div className='flex justify-between'>
              <div>
                <div className=''>
                  Quantity : <span> {itemInfo.quantity} </span>
                </div>
              </div>
              <div>
                <Text
                  {...{
                    className: 'text-nero text-sm font-medium leading-5',
                    content: `AED ${
                      itemInfo?.product?.price
                        ? parseFormatPriceValueFromAPI(getItemPrice(itemInfo))
                        : '' // TODO: introduce and use convertToCurrencyFormat here as well - https://bitbucket.org/evinops-hafla/hafla/pull-requests/11712/diff#comment-603398656
                    }`
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });

const CheckOutOrderSummaryOrganism = () => {
  const context = useUIContext();
  const { cartItemCount, defaultHostCartDetail } = context;
  const NRFQItems =
    filterItemsByCartItemGroupType({
      type: 'CHECKOUT_READY',
      items: defaultHostCartDetail?.cartItems
    }) || [];
  return (
    cartItemCount && (
      <div className='hidden md:block md:w-2/5'>
        <div className='h-15 box-border py-5 flex items-center justify-between'>
          <Text
            {...{
              className: 'text-nero text-base font-medium leading-5',
              content: 'Order Summary',
              HtmlTag: 'span'
            }}
          />
        </div>

        <div
          className={`${
            defaultHostCartDetail?.cartItems?.length > 4 ? 'max-h-96' : 'h-max'
          } overflow-auto custom-scroll pe-5.5`}
        >
          <CheckoutCartItemList {...{ NRFQItems }} />
        </div>
        <div className='pt-1 pe-5.5'>
          <SummaryFields
            {...{
              ...defaultHostCartDetail.derivedValues,
              cartItems: defaultHostCartDetail.cartItems,
              cartOrderScreenType: 'cart',
              nonTaxableCharge: defaultHostCartDetail.nonTaxableCharge
            }}
          />
        </div>
        <div className='rounded-box price-notes-margin'>
          <CartNotes {...context} />
        </div>
      </div>
    )
  );
};

export default CheckOutOrderSummaryOrganism;
