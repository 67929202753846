import Image from 'next/image';
import { useRouter } from 'next/router';

import { CTA } from '@/components/atomic/atoms';
import { Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import phoneConfig from '@/config/phone';
import { getPageURL, ROUTE_CONFIG } from '@/services/hostPortal.service';

const EmptyCart = ({ router, showContinueShopping = true }) => (
  <>
    <Text
      {...{
        content: 'Your Cart is Empty!',
        className: 'text-xl font-medium text-basic-black mt-10'
      }}
    />
    <Text
      {...{
        content: 'Click below to explore and add',
        className: 'text-base mt-2 text-basic-black'
      }}
    />
    <Text
      {...{
        content: 'products to the cart for your event!',
        className: 'text-base text-basic-black'
      }}
    />
    {showContinueShopping && (
      <div className='mt-6'>
        <CTA
          {...{
            children: 'Continue Shopping',
            buttonColor:
              'bg-gradient-to-r from-light-orange via-dark-orange to-rose text-white',
            className: 'text-sm font-medium px-10 py-3 font-light',
            onClick: () =>
              router.push(
                getPageURL({
                  pageName: ROUTE_CONFIG.HOME_PAGE.label
                })
              )
          }}
        />
      </div>
    )}
  </>
);

const CartNotFound = () => (
  <>
    <Text
      {...{
        content: 'Oh no! The cart you are looking for does not exist.',
        className: 'text-xl font-bold mt-10'
      }}
    />
    <Text
      {...{
        content: `Reach out to our event expert on ${phoneConfig.phoneNumbers.zendeskWhatsappMaqsam} for more details.`,
        className: 'text-sm text-silver mt-3'
      }}
    />
  </>
);

const EmptyCartBody = ({ cartFound, showContinueShopping }) => {
  const router = useRouter();
  const imageSrc = `${staticMediaStoreBaseURL}/cart/${
    cartFound ? 'shopping-cart-2.svg' : 'empty-cart.svg'
  }`;
  return (
    <div className='flex flex-col items-center mx-auto mt-20'>
      <Image
        alt='landing image'
        className='cursor-pointer'
        height={cartFound ? 240 : 200}
        objectFit='initial'
        src={imageSrc}
        width={cartFound ? 366 : 275}
      />
      {cartFound ? (
        <EmptyCart {...{ router, showContinueShopping }} />
      ) : (
        <CartNotFound />
      )}
    </div>
  );
};

export default EmptyCartBody;
