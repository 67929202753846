import Image from 'next/image';
import Link from 'next/link';
import { Fragment, useState } from 'react';

import { InvalidErrorModal, LoginButton } from '@/components/atomic/atoms';
import { PhoneCodes } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import phoneConfig from '@/config/phone';
import mobileNumber from '@/lib/validations/mobile-number';
import { getPageURL, ROUTE_CONFIG } from '@/services/hostPortal.service';
import { createUser } from '@/services/user.service';

const { firstCountryToShow, mobileNotStartZeroRegex } = phoneConfig;

const LoginComponent = ({
  countryCode,
  mobile,
  setCountryCode,
  setMobile,
  setOTP,
  setOtpSent
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [showInvalidMobileModal, setShowInvalidMobileModal] = useState(false);
  const sentOtp = async (event) => {
    event.preventDefault();
    const user = await createUser({
      mobile: `${countryCode}${mobile}`
    });
    if (user.status) {
      setOTP('');
      setOtpSent(true);
    } else {
      setErrorMessage(user.message ?? '');
      setShowInvalidMobileModal(true);
    }
  };

  const isInvalidMobile = () =>
    mobileNotStartZeroRegex.test(mobile)
      ? !mobileNumber.isMobileNumberValid(`${countryCode}${mobile}`)
      : true;

  return (
    <Fragment>
      {showInvalidMobileModal && (
        <InvalidErrorModal
          setShowInvalidOtpModal={setShowInvalidMobileModal}
          message={errorMessage ?? 'Invalid mobile number'}
        />
      )}
      <div className='pt-12 pb-20'>
        <h2 className='text-nero text-32px font-semibold leading-5 mb-8 uppercase'>
          {' '}
          Welcome to Hafla{' '}
        </h2>

        <form onSubmit={sentOtp}>
          <div className='rounded-2.5 mb-3 bg-white box-border shadow-category'>
            <div className='py-5'>
              <label className='text-dim-gray text-sm leading-5 font-medium mb-3 block px-5'>
                {' '}
                Country/Region{' '}
              </label>

              <div className='relative inline-flex items-center w-full cursor-pointer'>
                <PhoneCodes
                  onSelect={setCountryCode}
                  showFirst={firstCountryToShow}
                  defaultValue={countryCode}
                  id='countryCodes'
                  name='countryCodes'
                  className='scroll-element cursor-pointer w-full text-nero font-medium leading-5 bg-white
                  hover:border-gray-400 focus:outline-none appearance-none px-5'
                  optionClassName='text-base text-nero font-medium md:text-lg w-full'
                />
                <div className='absolute right-5'>
                  <Image
                    width={14}
                    height={8}
                    src={`${staticMediaStoreBaseURL}/icons/down-arrow.svg`}
                    alt='Down Arrow'
                  />
                </div>
              </div>
            </div>

            <div className='border-t border-platinum h-16 flex items-center'>
              <input
                required
                placeholder='Your phone number'
                type='number'
                className='appearance-none outline-none w-full font-normal h-14 px-5 overflow-hidden'
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
            </div>
          </div>

          <LoginButton
            disabled={isInvalidMobile()}
            className={`bg-brand-gradient text-white text-base font-medium h-12.5 py-4 rounded-md mb-4 block ${
              isInvalidMobile()
                ? 'opacity-50 hover:opacity-50'
                : 'hover:opacity-90'
            }`}
            type='submit'
          >
            Send OTP
          </LoginButton>

          <p className='text-nero text-xs font-medium leading-4'>
            {' '}
            By continuing, you agree to all
            <Link
              href={getPageURL({
                pageName: ROUTE_CONFIG.TERMS_AND_CONDITION.label
              })}
            >
              <a
                target='_blank'
                rel='noopener noreferrer'
                className='text-blue'
              >
                terms and conditions{' '}
              </a>
            </Link>{' '}
            and{' '}
            <Link
              href={getPageURL({
                pageName: ROUTE_CONFIG.PRIVACY_POLICY.label
              })}
            >
              <a
                target='_blank'
                rel='noopener noreferrer'
                className='text-blue'
              >
                policy
              </a>
            </Link>{' '}
            of Hafla.
          </p>
        </form>
      </div>
    </Fragment>
  );
};

export default LoginComponent;
