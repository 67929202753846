import Image from 'next/image';
import { useState } from 'react';

import { RichTextEditor, Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import { useIsMobile } from '@/helpers/screenResolutionHelper';

const Accordion = ({ heading, richText }) => {
  const [isSectionCollapsed, setIsSectionCollapsed] = useState(false);

  const toggleAccordionBody = () => setIsSectionCollapsed(!isSectionCollapsed);
  const [isMobile] = useIsMobile();
  return (
    <div
      onClick={toggleAccordionBody}
      className='flex flex-col gap-4 md:gap-6 border rounded-md border-platinum bg-lightgray p-3 md:p-6 cursor-pointer'
    >
      <div className='flex justify-between'>
        <Text
          {...{
            className: 'text-sm md:text-lg font-medium text-nero',
            content: heading,
            HtmlTag: 'h2'
          }}
        />
        <Image
          alt='arrow'
          className={`${
            isSectionCollapsed ? '-rotate-90' : 'rotate-90'
          } transform cursor-pointer`}
          height={isMobile ? 16 : 20}
          onClick={toggleAccordionBody}
          src={`${staticMediaStoreBaseURL}/icons/next-arrow.svg`}
          width={isMobile ? 16 : 20}
        />
      </div>
      {isSectionCollapsed && (
        <div className='border-t border-platinum pt-4 md:pt-6 text-xs md:text-sm font-light'>
          <RichTextEditor
            {...{
              readOnly: true,
              values: richText
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Accordion;
