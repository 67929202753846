import { Fragment } from 'react';

import { FormInputView } from '@/components/atomic/atoms';
import { emailFestivalFields, emailField } from '@/helpers/emailFestivalFields';

const FullSizeInputField = ({
  disabled = false,
  errors,
  fields = [],
  register,
  ...props
}) => (
  <Fragment>
    {fields.map(({ label, dbKeyName, type }) => (
      <div
        key={dbKeyName}
        className='mt-5'
      >
        <FormInputView
          {...{
            register,
            dbKeyName,
            disabled,
            errors,
            label,
            type,
            ...props
          }}
        />
      </div>
    ))}
  </Fragment>
);

const EmailFestivalRow = ({
  additionalStyleClass,
  disabled,
  errors,
  onlyEmailField,
  register
}) => (
  <FullSizeInputField
    {...{
      additionalStyleClass,
      disabled,
      errors,
      fields: onlyEmailField ? emailField : emailFestivalFields,
      register
    }}
  />
);

export default EmailFestivalRow;
