import { RichTextEditor } from '@/components/atomic/nuclei';

const ExternalNote = ({ note }) =>
  note && (
    <div>
      <div className='my-6 border-t border-platinum hidden md:block'></div>
      <div className='flex flex-col gap-4'>
        <div className='text-sm md:text-base font-medium'>Notes</div>
        <RichTextEditor
          {...{
            readOnly: true,
            values: note
          }}
        />
      </div>
    </div>
  );

export default ExternalNote;
