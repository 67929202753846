import { getPageURL, ROUTE_CONFIG } from 'services/hostPortal.service';

const userProfileTabList = [
  {
    tabName: 'Personal Details',
    icon: 'profile.svg',
    iconBackground:
      'bg-green-gradient p-1.5 rounded-2.5 h-8 w-8 flex justify-center items-center',
    routePath: 'personal-details',
    toNavigate: getPageURL({
      pageName: ROUTE_CONFIG.USER_PROFILE_PERSONAL_DETAILS.label
    })
  },
  {
    tabName: 'My Events',
    icon: 'calender-icon.svg',
    iconBackground:
      'bg-red-gradient p-1.5 rounded-2.5 h-8 w-8 flex justify-center items-center',
    routePath: 'event',
    toNavigate: getPageURL({
      pageName: ROUTE_CONFIG.USER_PROFILE_MY_EVENTS.label
    })
  },
  {
    tabName: 'My Orders',
    icon: 'order.svg',
    iconBackground:
      'bg-pink-gradient p-1.5 rounded-2.5 h-8 w-8 flex justify-center items-center',
    routePath: 'my-orders',
    toNavigate: getPageURL({
      pageName: ROUTE_CONFIG.MY_ORDERS.label
    })
  },
  {
    tabName: 'Address',
    icon: 'location-pin.svg',
    iconBackground:
      'bg-purple-gradient p-1.5 rounded-2.5 h-8 w-8 flex justify-center items-center',
    routePath: 'address',
    toNavigate: getPageURL({
      pageName: ROUTE_CONFIG.USER_PROFILE_ADDRESS.label
    })
  },
  {
    tabName: 'Get help',
    icon: 'get-help.svg',
    iconBackground:
      'bg-red-gradient p-1.5 rounded-2.5 h-8 w-8 flex justify-center items-center',
    routePath: 'get-help',
    toNavigate: getPageURL({
      pageName: ROUTE_CONFIG.USER_PROFILE_GET_HELP.label
    })
  }
];

export default userProfileTabList;
