import * as HttpService from 'services/http.service';
import {
  POST_CHECKOUT_EVENT_PRIORITY_INQUIRY,
  POST_VENDOR_REGISTRATION_INQUIRY
} from 'services/url.service';

export const sendPriorityEventInquiryDetails = (inquiryDetails) =>
  HttpService.postWithoutAuth(
    POST_CHECKOUT_EVENT_PRIORITY_INQUIRY,
    inquiryDetails
  );

export const sendVendorRegistrationInquiryDetails = (inquiryDetails) =>
  HttpService.postWithoutAuth(POST_VENDOR_REGISTRATION_INQUIRY, inquiryDetails);
