import { Modal, ModalBody } from 'reactstrap';

import {
  DatePicker,
  EventTypeRow,
  InputField
} from '@/components/atomic/atoms';
import { Button, CloseIcon, Text } from '@/components/atomic/nuclei';

const ActionButton = ({ onClick }) => (
  <Button
    {...{
      children: 'Submit',
      className: `bg-coral px-5 py-2 text-white text-sm md:text-base rounded-lg leading-4 font-medium focus:outline-none w-full md:max-w-40 `,
      onClick,
      width: 'w-32'
    }}
  />
);

const DatePickerField = ({
  className = 'flex w-1/2 gap-2 flex-col pb-2 ',
  dbName,
  errors,
  label,
  minDate,
  selectedDate,
  setSelectedDate,
  setValue
}) => (
  <div className={className}>
    <Text
      className='text-nero'
      content={label}
    />
    <DatePicker
      {...{
        className: 'py-2 padding-none',
        dbName,
        errors,
        minDate,
        selectedDate,
        setSelectedDate,
        setValue,
        showIcon: false
      }}
    />
  </div>
);

const QuoteModal = ({
  checkoutEventList,
  clearErrors,
  control,
  errors,
  eventDate,
  getValues,
  handleSubmit,
  onClickRequestForQuote,
  register,
  setIsRequestForQuote,
  setSelectedEventDate,
  setValue
}) => (
  <div className='flex gap-5 justify-center h-screen mx-auto text-sm font-medium'>
    <div className='self-center w-41 z-20 bg-white rounded-lg px-8 py-6'>
      <CloseIcon {...{ onClick: () => setIsRequestForQuote(false) }} />
      <div className='flex flex-col gap-4'>
        <InputField
          {...{
            className: 'rounded-md',
            disabled: true,
            id: 'name',
            label: 'Name',
            labelClass: 'mb-2 flex',
            name: 'name',
            type: 'text',
            value: getValues('customerName')
          }}
        />
        <InputField
          {...{
            className: 'rounded-md',
            disabled: true,
            id: 'ContactNumber',
            label: 'Contact Number',
            labelClass: 'mb-2 flex',
            name: 'ContactNumber',
            type: 'text',
            value: getValues('customerMobile')
          }}
        />
        <EventTypeRow
          {...{
            clearErrors,
            control,
            dbkeyForFestival: 'eventTitle',
            dbName: 'checkoutEventId',
            disabled: false,
            errors,
            eventTypeOptions: checkoutEventList,
            getValues,
            placeholderLabel: 'Event Type',
            register,
            setValue
          }}
        />
        <DatePickerField
          {...{
            dbName: 'eventDate',
            errors,
            label: 'Event Date',
            minDate: 'Tue May 09 2023 18:35:20 GMT+0530',
            selectedDate: eventDate,
            setSelectedDate: setSelectedEventDate,
            setValue
          }}
        />
        <div className='self-end'>
          <ActionButton
            {...{ onClick: handleSubmit(onClickRequestForQuote) }}
          />
        </div>
      </div>
    </div>
  </div>
);
const RequestForQuoteModal = ({
  checkoutEventList,
  clearErrors,
  control,
  errors,
  eventDate,
  getValues,
  handleSubmit,
  onClickRequestForQuote,
  register,
  setIsRequestForQuote,
  setSelectedEventDate,
  setValue
}) => (
  <Modal
    backdrop={true}
    centered
    fade={false}
    isOpen={true}
    size='lg'
  >
    <ModalBody>
      <QuoteModal
        {...{
          checkoutEventList,
          clearErrors,
          control,
          errors,
          eventDate,
          getValues,
          handleSubmit,
          onClickRequestForQuote,
          register,
          setIsRequestForQuote,
          setSelectedEventDate,
          setValue
        }}
      />
    </ModalBody>
  </Modal>
);

export default RequestForQuoteModal;
